import React from "react";
import "./index.css";
import "./../../lib/extentions";
import {
  PositionAnimatedObject,
  SizeAnimatedObject,
  RotateAnimatedObject,
  Velocity,
} from "../../lib/animated-object";

export default React.forwardRef(
  (
    {
      x = 0,
      y = 0,
      width = 200,
      height = 300,
      backgroundUrl,
      onMounted,
      onObjectClicked,
      children,
    },
    ref
  ) => {
    const animatedObjRef = React.useRef();

    React.useImperativeHandle(ref, () => ({
      instantMove: (x, y) => {
        animatedObjRef.current.posAnimation.pos = {
          ...animatedObjRef.current.pos,
          x,
          y,
        };
        animatedObjRef.current.posAnimation.draw();
      },
      async move(x, y, speed, stepFunction) {
        const { x: oldX, y: oldY } = animatedObjRef.current.posAnimation.pos;
        const angle = Math.angleBetween(x, y, oldX, oldY);
        const velocity = {
          x: speed * Math.cos(angle),
          y: speed * Math.sin(angle),
        };

        animatedObjRef.current.posAnimation.stop();
        animatedObjRef.current.posAnimation.velocity = Velocity.create2d(
          velocity.x,
          velocity.y
        );

        // run until we are no longer getting closer to the targetted position
        var oldDistance = Math.distanceBetween(x, y, oldX, oldY);
        await animatedObjRef.current.posAnimation.runUntil(
          ({ position: pos }) => {
            stepFunction && stepFunction(pos);
            const newDistance = Math.distanceBetween(x, y, pos.x, pos.y);
            if (newDistance > oldDistance) {
              animatedObjRef.current.posAnimation.pos.x = x;
              animatedObjRef.current.posAnimation.pos.y = y;
              return true;
            }
            oldDistance = newDistance;
            return false;
          }
        );
      },
      instantRotate: (angle) => {
        animatedObjRef.current.rotAnimation.rotation = {
          ...animatedObjRef.current.rotAnimation.rotation,
          z: angle,
        };
        animatedObjRef.current.rotAnimation.draw();
      },
      rotate: async (angle, degreesPerSec) => {
        const angleDif = angle - animatedObjRef.current.rotAnimation.rotation.z;
        if (angleDif < 0) degreesPerSec = -degreesPerSec;
        animatedObjRef.current.rotAnimation.velocity = Velocity.create3d(
          0,
          0,
          degreesPerSec
        );
        var oldDif = Math.abs(
          angle - animatedObjRef.current.rotAnimation.rotation.z
        );
        await animatedObjRef.current.rotAnimation.runUntil(
          ({ rotation: rot }) => {
            const newDif = Math.abs(angle - rot.z);
            if (newDif > oldDif) {
              animatedObjRef.current.rotAnimation.rotation.z = angle;
              console.log("FINISHED ANIM", oldDif, newDif);
              return true;
            }
            oldDif = newDif;
            return false;
          }
        );
      },
      getElement: () => animatedObjRef.current,
      getPos: () => animatedObjRef.current.posAnimation.pos,
      getRot: () => animatedObjRef.current.rotAnimation.rotation.z,
      instantResize : (width, height)=>{
        animatedObjRef.current.sizeAnimation.width = width;
        animatedObjRef.current.sizeAnimation.height = height;
        animatedObjRef.current.sizeAnimation.draw();
      },
      instantResizePercent : (percent)=>{
        animatedObjRef.current.sizeAnimation.width *= percent / 100;
        animatedObjRef.current.sizeAnimation.height *= percent / 100;
        animatedObjRef.current.sizeAnimation.draw();
      },
      resize: async (width, height, speed)=>{
        const difX = width - animatedObjRef.current.sizeAnimation.width;
        const difY = height - animatedObjRef.current.sizeAnimation.height;
        animatedObjRef.current.sizeAnimation.velocity = Velocity.create2d(speed * difX/(difX+difY), speed * difY/(difX+difY));
        var oldDif = Math.abs(difX + difY);
        await animatedObjRef.current.sizeAnimation.runUntil(
          ({ _, __, ___, ____, _____, width:newWidth, height:newHeight }) => {
            const newDif = Math.abs((width-newWidth) + (height-newHeight));
            if (newDif > oldDif) {
              animatedObjRef.current.sizeAnimation.width = width;
              animatedObjRef.current.sizeAnimation.height = height;
              console.log("FINISHED ANIM", oldDif, newDif);
              return true;
            }
            oldDif = newDif;
            return false;
          }
        );
      },
      resizePercent: async (percent, speed)=>{
        const width = animatedObjRef.current.sizeAnimation.width * percent/ 100;
        const height = animatedObjRef.current.sizeAnimation.height * percent/ 100;
        const difX = width - animatedObjRef.current.sizeAnimation.width;
        const difY = height - animatedObjRef.current.sizeAnimation.height;
        animatedObjRef.current.sizeAnimation.velocity = Velocity.create2d(speed * difX/(difX+difY), speed * difY/(difX+difY));
        var oldDif = Math.abs(difX + difY);
        await animatedObjRef.current.sizeAnimation.runUntil(
          ({ _, __, ___, ____, _____, width:newWidth, height:newHeight }) => {
            const newDif = Math.abs((width-newWidth) + (height-newHeight));
            if (newDif > oldDif) {
              animatedObjRef.current.sizeAnimation.width = width;
              animatedObjRef.current.sizeAnimation.height = height;
              console.log("FINISHED ANIM", oldDif, newDif);
              return true;
            }
            oldDif = newDif;
            return false;
          }
        );
      },
      getSize: ()=> ({width:animatedObjRef.current.sizeAnimation.width, height:animatedObjRef.current.sizeAnimation.height})
    }),[animatedObjRef]);

    React.useEffect(() => {
      if (!animatedObjRef || !animatedObjRef.current) return;
      animatedObjRef.current.style.backgroundImage = `url(${backgroundUrl})`;

      animatedObjRef.current.posAnimation = new PositionAnimatedObject(
        animatedObjRef.current
      );
      animatedObjRef.current.posAnimation.pos.x = x;
      animatedObjRef.current.posAnimation.pos.y = y;
      animatedObjRef.current.rotAnimation = new RotateAnimatedObject(
        animatedObjRef.current,
        true
      );
      animatedObjRef.current.sizeAnimation = new SizeAnimatedObject(
        animatedObjRef.current,
        width,
        height,
        true
      );
      animatedObjRef.current.sizeAnimation.draw();

      onMounted && onMounted(animatedObjRef.current);
    }, [animatedObjRef, x, y, width, height, backgroundUrl, onMounted]);

    return (
      <div
        className="animated-object"
        ref={animatedObjRef}
        onClick={onObjectClicked}
      >
        {children}
      </div>
    );
  }
);
