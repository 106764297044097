import {TimeFunctions} from './extentions'

class PreloaderClass
{
    constructor(){
        this.queue = [];
    }

    addAudio(url){
        this.queue.push({
            url, type:"audio"
        });
        return this;
    }

    addImage(url){
        this.queue.push({
            url, type:"image"
        });
        return this;
    }
    
    async preloadAudio(url, timeout = 5000){
        return new Promise( (resolve, reject)=>{
            var audio = new Audio();

            const timer = setTimeout(resolve, timeout);
            audio.addEventListener('canplaythrough', ()=>{
                clearTimeout(timer);
                resolve();
            }, false);
            audio.src = url;

        }, []);
    }
    async preloadImage(url, timeout = 5000){
        return new Promise( (resolve, reject)=>{
            const timer = setTimeout(resolve, timeout);
            const image = new Image();
            image.onload = ()=>{
                clearTimeout(timer);
                resolve();
            }
            image.src = url;
        }, []);
    }

    async preload(){
        for(let i=0;i<this.queue.length;i++){
            try{
                const item = this.queue[i];
                if(item.type === 'image') await this.preloadImage(item.url);
                if(item.type === 'audio') await this.preloadAudio(item.url);
                console.log("Preloaded - ", item.url);
                await TimeFunctions.wait(500);
            }
            catch{

            }
        }
    }
}

const Preloader = new PreloaderClass();
export default Preloader;
