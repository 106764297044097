import { useEffect, useState } from "react";

import Observable from "./../lib/observable";

var isRedButtonDisabled = false;
export default function Router({ children }) {
  const [page, setPage] = useState(undefined);
  const [router, setRouter] = useState({});
  useEffect(() => {
    const newRouter = {};
    if (children) {
      if (children.length) {
        for (let i = 0; i < children.length; i++) {
          newRouter[children[i].props.name] = children[i];
        }
      } else {
        newRouter[children.props.name] = children;
      }
    }
    setRouter(newRouter);
  }, [children, setRouter]);

  useEffect(() => {
    Observable.subscribe("navigating-80%", (newPage) => {
      console.log("navigating", newPage);
      setPage(newPage);
    });
    Observable.subscribe("in-menu", () => {
      console.log("in menus");
      setPage(null);
    });
  }, [setPage]);

  var computedPage = page;
  if (page === undefined) {
    //check window.location
    computedPage = window.location.pathname.substring(1);
    if(computedPage==="" || computedPage === "/"){
      computedPage = "home";
    }
  }

  const navigate = () => {
    if(isRedButtonDisabled) return;
    isRedButtonDisabled = true;
    Observable.push("hiding-page");
    setTimeout(() => {
      Observable.push("leave-page");
    }, 2000);
    setTimeout(()=>{
      isRedButtonDisabled = false;
    }, 8000)
  };

  if (!computedPage) return null;

  return (
    <>
      {router && router[computedPage]}
      {computedPage && computedPage !== "menu" && (
        <button
          className="red-button"
          onClick={navigate}
          style={{
            backgroundColor: "transparent",
            backgroundImage: "url('/red-button.png')",
          }}
        ></button>
      )}
    </>
  );
}
