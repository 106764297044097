import skills from "../../data/skills";
import "./game.css";

export default class Game {
  constructor(container) {
    this.container = container;
    this.size = [20, 20];
    this.cellSize = 100 / this.size[0];
    this.directionDeltas = [
      [0, -1],
      [1, 0],
      [0, 1],
      [-1, 0],
    ];
    this.reset();

    this.animationStepRef = this.animationStep.bind(this);
  }

  reset() {
    this.direction = 0;
    this.cells = [];
    this.snake = [{ pos: [1, 18] }];
    this.state = "paused";
    this.speed = 5;
    this.allGoodies = skills.experiencePage;
    this.discoveredGoodies = [];

    this.skillsToBeAdded = [];
  }

  setOnGoodieFound(onGoodieFound) {
    this.onGoodieFound = onGoodieFound;
  }

  setOnStart(onStart) {
    this.onStart = onStart;
  }

  start() {
    this.lastAnimationFrame = new Date().getTime();
    this.animationFrame = window.requestAnimationFrame(this.animationStepRef);
    this.state = "running";
    this.onStart && this.onStart();
  }

  pause() {
    window.cancelAnimationFrame(this.animationFrame);
    this.state = "paused";
  }

  animationStep() {
    const currentTime = new Date().getTime();
    const delta = currentTime - this.lastAnimationFrame;
    if (delta / 1000 >= 1 / this.speed) {
      this.lastAnimationFrame = currentTime;
      this.clearSnake();
      this.moveSnake();
      this.drawSnake();
    }
    this.animationFrame = window.requestAnimationFrame(this.animationStepRef);
  }

  setContainer(container) {
    this.container = container;
  }

  construct() {
    this.generateTiles();
    this.drawSnake();
    this.addGoodie();
  }

  addGoodie() {
    const index = this.discoveredGoodies.length;
    if (this.allGoodies.length <= index) return;
    const goodie = {
      elem: document.createElement("div"),
      goodie: this.allGoodies[index],
    };
    goodie.elem.classList.add("snake-goodie");
    goodie.elem.style.backgroundImage = `url(${goodie.goodie.image})`;
    goodie.elem.style.left = `${goodie.goodie.pos[0] * this.cellSize}%`;
    goodie.elem.style.top = `${goodie.goodie.pos[1] * this.cellSize}%`;
    goodie.elem.style.width = `${goodie.goodie.size[0] * this.cellSize}%`;
    goodie.elem.style.height = `${goodie.goodie.size[1] * this.cellSize}%`;
    this.container.appendChild(goodie.elem);
    this.discoveredGoodies.push(goodie);
  }

  isBorderCell(i, j) {
    if (
      i === 0 ||
      i === this.size[1] - 1 ||
      j === 0 ||
      j === this.size[0] - 1
    ) {
      return true;
    }
    return false;
  }

  generateTiles() {
    if (this.container) {
      this.cells = [];
      for (let i = 0; i < this.size[1]; i++) {
        for (let j = 0; j < this.size[0]; j++) {
          const cell = document.createElement("div");
          cell.classList.add("snake-cell");
          if (this.isBorderCell(i, j)) {
            cell.style.backgroundImage = "url('/portal.png')";
            cell.classList.add("snake-border-cell");
          }
          cell.style.width = `${this.cellSize}%`;
          this.container.appendChild(cell);
          this.cells.push(cell);
        }
      }
    }
  }

  getPos(posArr) {
    return posArr[1] * this.size[0] + posArr[0];
  }

  clearSnake() {
    for (let i = 0; i < this.snake.length; i++) {
      const pos = this.getPos(this.snake[i].pos);
      this.cells[pos].classList.remove("snake-head-cell");
      this.cells[pos].classList.remove("snake-body-cell");
      this.cells[pos].style.transform = null;
      this.cells[pos].style.backgroundImage = null;
    }
  }

  moveSnake() {
    if (this.state === "paused") return;
    const snake = this.snake;
    const head = snake[0];

    const goodie = this.discoveredGoodies[this.discoveredGoodies.length - 1];
    if (this.isGoodieHit() && !goodie.isPassive) {
      this.onGoodieFound && this.onGoodieFound(goodie.goodie);
      goodie.isPassive = true;
      goodie.elem.classList.add("passive");
      this.addSkills();
      // add next goodie
      this.addGoodie();
      return;
    }

    if (this.skillsToBeAdded.length > 0) {
      const skill = this.skillsToBeAdded.pop();
      // need to inject a skill
      const snakeSkill = {
        pos: [...head.pos],
        id: skill.id,
        image: skill.image,
        name: skill.name,
      };
      // add the head into the correct position
      head.pos[0] += this.directionDeltas[this.direction][0];
      head.pos[1] += this.directionDeltas[this.direction][1];

      // inject the skill
      this.snake.splice(1, 0, snakeSkill);
      return;
    }

    // add the head into the correct position
    const headPos = [...head.pos];
    // move all segments one position towards the head
    for (let i = this.snake.length - 1; i >= 1; i--) {
      this.snake[i].pos = [...this.snake[i - 1].pos];
    }
    head.pos = headPos;
    head.pos[0] += this.directionDeltas[this.direction][0];
    head.pos[1] += this.directionDeltas[this.direction][1];

    if (head.pos[0] === 0) head.pos[0] = this.size[1] - 2;
    if (head.pos[1] === 0) head.pos[1] = this.size[0] - 2;
    if (head.pos[0] === this.size[1] - 1) head.pos[0] = 1;
    if (head.pos[1] === this.size[0] - 1) head.pos[1] = 1;
  }

  addSkills() {
    const { goodie } =
      this.discoveredGoodies[this.discoveredGoodies.length - 1];
    const allSkills = skills.list;
    for (let i = 0; i < goodie.skills.length; i++) {
      const skill = allSkills.find((a) => a.id === goodie.skills[i]);
      this.skillsToBeAdded.unshift(skill);
    }
  }

  drawSnake() {
    let headPos = this.getPos(this.snake[0].pos);
    const head = this.cells[headPos];
    head.classList.add("snake-head-cell");
    switch (this.direction) {
      case 0:
        head.style.transform = "rotate(0deg)";
        break;
      case 1:
        head.style.transform = "rotate(90deg)";
        break;
      case 2:
        head.style.transform = "rotate(180deg)";
        break;
      case 3:
        head.style.transform = "rotate(270deg)";
        break;
      default:
        break;
    }
    // draw body
    for (let i = 1; i < this.snake.length; i++) {
      const pos = this.getPos(this.snake[i].pos);
      const elem = this.cells[pos];
      elem.classList.add("snake-body-cell");
      elem.dataset.name = this.snake[i].name;
      //elem.style.backgroundImage = `url(${this.snake[i].image}`;
      elem.style.setProperty("--background", `url(${this.snake[i].image}`);
      elem.dataset.background = `url(${this.snake[i].image}`;
    }
  }

  isGoodieHit() {
    const headPos = this.snake[0].pos;
    const goodie =
      this.discoveredGoodies[this.discoveredGoodies.length - 1].goodie;
    if (
      headPos[0] >= goodie.pos[0] &&
      headPos[0] <= goodie.pos[0] + goodie.size[0] &&
      headPos[1] >= goodie.pos[1] &&
      headPos[1] <= goodie.pos[1] + goodie.size[1]
    )
      return true;
    return false;
  }

  onKeyDown(e) {
    switch (e.code) {
      case "ArrowUp":
      case "KeyW":
        this.direction = 0;
        this.state !== "running" && this.start();
        break;
      case "ArrowRight":
      case "KeyD":
        this.direction = 1;
        this.state !== "running" && this.start();
        break;
      case "ArrowDown":
      case "KeyS":
        this.direction = 2;
        this.state !== "running" && this.start();
        break;
      case "ArrowLeft":
      case "KeyA":
        this.direction = 3;
        this.state !== "running" && this.start();
        break;
      case "Space":
        this.state === "running" ? this.pause() : this.start();
        break;
      default:
        break;
    }
    console.log(this.state);
  }
}
