import React from "react";
import "./astronaut-pilu.css";
import { Animated } from "../../../components";

import {
  PositionAnimatedObject,
  RotateAnimatedObject,
  SizeAnimatedObject,
  Velocity,
} from "../../../lib/animated-object";
import { TimeFunctions } from "../../../lib/extentions";

export default React.forwardRef(({ x = 0, y = 0 }, ref) => {
  const animatedRef = React.useRef();

  const putMask = React.useCallback(() => {
    if (animatedRef && animatedRef.current) {
      const mask = animatedRef.current
        .getElement()
        .querySelector(".astronaut-pilu-mask");
      mask && mask.classList.remove("remove");
    }
  }, [animatedRef]);
  const removeMask = React.useCallback(() => {
    if (animatedRef && animatedRef.current) {
      const mask = animatedRef.current
        .getElement()
        .querySelector(".astronaut-pilu-mask");
      mask && mask.classList.add("remove");
    }
  }, [animatedRef]);

  const shoot = async (speed = 250, targetX, targetY, shootFinishTestFc = undefined) => {
    return new Promise(async (resolve) => {
      let pos = {...animatedRef.current.getPos()};
      const size = animatedRef.current.getSize();
      
      pos.x += size.width * 40 / 100;
      pos.y += size.height * 65 / 100;
      
      const angle = Math.angleBetween(targetX, targetY, pos.x, pos.y) 

      var div = document.createElement("div");
      div.className = "pilu-bullet";
      document.body.appendChild(div);
      const audio = new Audio("/sounds/shoot.mp3");
      audio.volume = 0.5;
      audio.play().catch(() => {});
      div.posAnimation = new PositionAnimatedObject(div);
      div.rotAnimation = new RotateAnimatedObject(div);
      div.sizeAnimation = new SizeAnimatedObject(div);
      div.sizeAnimation.width = 0;
      div.sizeAnimation.height = 0;
      div.sizeAnimation.draw();
      
      const pxPerSec = 250;
      const fiftyPxTime = 50 * 1000 / pxPerSec;
      div.sizeAnimation.velocity = Velocity.create2d(40 * 1000/fiftyPxTime , 3 * 1000/fiftyPxTime);
      div.sizeAnimation.runUntil(({width, height})=>{
        return width>=40 && height>=3;
      })

      div.posAnimation.pos.y = pos.y;
      div.posAnimation.pos.x = pos.x;
      div.posAnimation.velocity = Velocity.create2d(-speed, 0);
      const shootAngle = angle;
 
      div.rotAnimation.rotation = {
        ...div.rotAnimation.rotation,
        z: Math.toDegrees(shootAngle),
      };
      div.rotAnimation.draw();
      await TimeFunctions.wait(20);
      div.posAnimation.velocity = Velocity.create2d(
        speed * Math.cos(shootAngle),
        speed * Math.sin(shootAngle)
      );
      if (shootFinishTestFc) {
        await div.posAnimation.runUntil(shootFinishTestFc);
        document.body.removeChild(div);
        resolve();
      } else {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        div.posAnimation.runUntil(({ position }) => {
          if (
            position.x < -100 ||
            position.y < -100 ||
            position.x > screenWidth + 100 ||
            position.y > screenHeight + 100
          ) {
            document.body.removeChild(div);
            return true;
          }
        });
        resolve();
      }
    });
  }

  const randomShoot = React.useCallback(()=>{
    const x = Math.randomBetween(0, window.innerWidth);
    const y = Math.randomBetween(0, window.innerHeight);
    shoot(250, x, y)
  }, [])

  React.useImperativeHandle(
    ref,
    () => ({
      putMask,
      removeMask,
      width:200,
      instantMove: animatedRef.current.instantMove,
      move: async (x, y) => {
        let angle = -12;
        let speed = -40;
        const currentX = animatedRef.current.getPos().x;
        if (x > currentX) {
          angle *= -1;
          speed *= -1;
        } else if (x === currentX) {
          angle = 0;
          speed = 0;
        }
        animatedRef.current.rotate(angle, speed);
        await animatedRef.current.move(x, y, 300);
        animatedRef.current.instantRotate(0);
      },
      instantResize:animatedRef.current.instantResize,
      instantResizePercent: animatedRef.current.instantResizePercent,
      resizePercent: animatedRef.current.resizePercent,
      getPos: animatedRef.current.getPos,
      getSize: animatedRef.current.getSize,
      instantRotate: animatedRef.current.instantRotate,
      shoot,
      randomShoot
    }),
    [animatedRef, putMask, removeMask, randomShoot]
  );

  const onClick = ()=>{
    randomShoot();
  }
  return (
    <Animated
      ref={animatedRef}
      x={x}
      y={y}
      width={200}
      height={160}
      onObjectClicked={onClick}
      backgroundUrl="/astronaut/astronaut-pilu.png"
    >
      <img
        className="astronaut-pilu-mask"
        src="/astronaut/astronaut-pilu-mask.png"
        alt="mask"
      />
    </Animated>
  );
});
