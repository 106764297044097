import { useEffect, forwardRef } from "react";
import "./form.css";

export default forwardRef(
  (
    { width, height, left, top, animationX, animationY, children, hide },
    ref
  ) => {
    useEffect(() => {
      if (!ref || !ref.current) return;
      if (hide) {
        ref.current.style.width = width;
        ref.current.style.height = height;
        setTimeout(() => {
          if (!ref || !ref.current) return;
          var transform = null;
          ref.current.style.transform = transform;
          ref.current.style.width = "0px";
          ref.current.style.height = "0px";
        }, 200);
        return;
      }

      ref.current.style.width = "0px";
      ref.current.style.height = "0px";
      ref.current.style.top = animationY + "px";
      ref.current.style.left = animationX + "px";
      ref.current.classList.add("animated");
      setTimeout(() => {
        if (!ref || !ref.current) return;
        var transform = `translate(${left - animationX}px, ${
          top - animationY
        }px) rotateY(360deg)`;
        transform = `translate(${left - animationX}px, ${top - animationY}px)`;
        console.log("TRANSOFRM", transform);
        ref.current.style.transform = transform;
        ref.current.style.width = width;
        ref.current.style.height = height;
      }, 200);
    }, [hide, animationX, animationY, height, left, ref, top, width]);

    return (
      <div className="form" ref={ref}>
        <div className="form-content">{children}</div>
      </div>
    );
  }
);
