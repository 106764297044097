export class Observable {
  constructor() {
    this.subscribers = {};
  }

  subscribe(key, subscriber) {
    if (!subscriber) return;
    if (!this.subscribers[key]) this.subscribers[key] = [];
    const index = this.subscribers[key].findIndex((a) => a === subscriber);
    if (index === -1) this.subscribers[key].push(subscriber);
  }

  unsubscribe(suscriber) {
    const keys = Object.keys(this.subscribers);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const subscriberIndex = this.subscribers[key].findIndex(
        (a) => a === suscriber
      );
      if (subscriberIndex >= 0) {
        this.subscribers[key].splice(subscriberIndex, 1);
        if (this.subscribers[key].length === 0) {
          delete this.subscribers[key];
        }
      }
    }
  }

  push(key, object) {
    const subscribers = this.subscribers[key];
    if (subscribers) {
      subscribers.forEach((subscriber) => {
        try {
          subscriber && subscriber(object);
        } catch (err) {
          console.error("Error in subscriber", err);
        }
      });
    }
  }
}

export default new Observable();
