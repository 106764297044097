import React, { useEffect } from "react";

import Observable from "./../../lib/observable";
import { TimeFunctions } from "./../../lib/extentions";
import CenterPoint from "../../components/center-point";

import "./hobbies.css";
import AstronautPilu from "./astronaut-pilu";
import AstronautShery from "./astronaut-shery";
import "./../../lib/extentions";
import {
  Acceleration,
  PositionAnimatedObject,
  RotateAnimatedObject,
  Velocity,
} from "../../lib/animated-object";
import { Animated, StorkSprite } from "../../components";

import { astronaut, showMessage } from "./../../components/astronaut/";

var Hobbies_GirlsJumping = false;
var Hobbies_Exiting = false;

export default function Hobbies() {
  const piluRef = React.useRef();
  const sheryRef = React.useRef();
  const girlsRef = React.useRef();
  const basketRef = React.useRef();
  const moviesRef = React.useRef();
  const gamesRef = React.useRef();
  const scifiRef = React.useRef();
  const partyRef = React.useRef();
  const booksRef = React.useRef();
  const storkRef = React.useRef();
  const centerPointRef = React.useRef();

  const checkExiting = () => {
    if (Hobbies_Exiting) {
      throw new Error("Page exiting, canceling promise");
    }
    return true;
  };

  const bogdanAstronautAppearence = async (timeAcceleration = 1) => {
    return new Promise((resolve) => {
      const x = 150;
      const y = window.innerHeight / 2 - 130;
      Observable.push("astronaut-command-move", {
        x,
        y,
        speed: 250 * timeAcceleration,
        onFinish: () => {
          resolve();
        },
      });
    });
  };

  const showHobbiesAnimation = async (timeAcceleration = 1) => {
    const transitionTime = 2;
    const appearTime = 2;
    const growTime = 0.3;
    const appearTimeDelay = 0.5;
    return new Promise((resolve) => {
      const hobbieItems = document.getElementsByClassName("hobbies-item");
      const totalAnimationTime =
        Math.max(transitionTime, appearTime) *
        (hobbieItems.length - 1) *
        appearTimeDelay;

      for (let i = 0; i < hobbieItems.length; i++) {
        hobbieItems[i].style.setProperty(
          "--transition-time",
          `${transitionTime / timeAcceleration}s`
        );
        hobbieItems[i].style.setProperty(
          "--appear-time",
          `${appearTime / timeAcceleration}s`
        );
        hobbieItems[i].style.setProperty(
          "--grow-time",
          `${growTime / timeAcceleration}s`
        );
        hobbieItems[i].style.setProperty(
          "--appear-time-delay",
          `${appearTimeDelay / timeAcceleration}s`
        );
        hobbieItems[i].classList.add("animated");
      }

      setTimeout(() => {
        resolve();
        if (!hobbieItems) return;
        for (let i = 0; i < hobbieItems.length; i++) {
          hobbieItems[i] && hobbieItems[i].classList.remove("animated");
          hobbieItems[i] && hobbieItems[i].classList.add("appeared");
        }
      }, (totalAnimationTime * 1000) / timeAcceleration);
    });
  };

  const jumpGirls = React.useCallback(
    async (timeAcceleration) => {
      if (Hobbies_GirlsJumping) return;
      if (girlsRef && girlsRef.current) {
        Hobbies_GirlsJumping = true;
        girlsRef.current.classList.add("jumping");
        girlsRef.current.style.setProperty("--transition-time", `3s`);
        await TimeFunctions.wait(50);
        girlsRef.current.classList.add("jump");
      }
    },
    [girlsRef]
  );

  const resetJumpGirls = React.useCallback(async () => {
    if (!Hobbies_GirlsJumping) return;
    girlsRef.current.classList.remove("jump");
  }, [girlsRef]);

  const shootHobbie = React.useCallback(
    async (hobbieElem, timeAcceleration) => {
      const box = hobbieElem.getBoundingClientRect();
      hobbieElem.classList.add("fallen");
      hobbieElem.posAnimation = new PositionAnimatedObject(hobbieElem);
      hobbieElem.posAnimation.pos.x = box.x;
      hobbieElem.posAnimation.pos.y = box.y;
      hobbieElem.posAnimation.velocity = Velocity.create2d(
        Math.randomBetween(-20, 120),
        Math.randomBetween(-100, -150) * timeAcceleration
      );
      hobbieElem.posAnimation.acceleration = Acceleration.createStatic2d(
        0,
        100 * timeAcceleration
      );

      hobbieElem.rotAnimation = new RotateAnimatedObject(hobbieElem, true);
      hobbieElem.rotAnimation.velocity = Velocity.create3d(
        0,
        0,
        Math.randomBetween(-30, 30) * timeAcceleration
      );
      hobbieElem.rotAnimation.start();
      const targetY = window.innerHeight - hobbieElem.clientHeight * 2;
      await hobbieElem.posAnimation.runUntil(({ position }) => {
        if (!hobbieElem || !hobbieElem.posAnimation) return true;
        if (position.y > targetY) {
          hobbieElem.posAnimation.pos.y = targetY;
          hobbieElem.rotAnimation.stop();
          return true;
        }
      });
    },
    []
  );

  const targetAndShoot = React.useCallback(
    async (timeAcceleration) => {
      const peekRifleXOffset = 50;
      const girlsBox = girlsRef.current.getBoundingClientRect();
      const girlsCenter = {
        x: girlsBox.x + girlsBox.width / 2,
        y: girlsBox.y + girlsBox.height / 2,
      };
      const basketBox = basketRef.current.getBoundingClientRect();
      const basketCenter = {
        x: basketBox.x + basketBox.width / 2,
        y: basketBox.y + basketBox.height / 2,
      };

      const basketGirlsRatio =
        (girlsCenter.x - basketCenter.x) / (girlsCenter.y - basketCenter.y);
      try {
        checkExiting() &&
          (await sheryRef.current.peekRight(
            92 * timeAcceleration,
            2500 / timeAcceleration,
            window.innerWidth / basketGirlsRatio + 230 / 2
          ));
        checkExiting() &&
          (await sheryRef.current.peekRifle(
            -peekRifleXOffset,
            30 * timeAcceleration
          ));
        showMessage("Watch out, girls!");
        checkExiting() &&
          (await sheryRef.current.target(
            girlsCenter.x,
            girlsCenter.y + 20,
            90 * timeAcceleration,
            150 / timeAcceleration,
            500 / timeAcceleration,
            true,
            52
          ));
        const girlsJumpDistance = { thsh: 250 / timeAcceleration, height: 150 };
        checkExiting() &&
          (await sheryRef.current.shoot(
            250 * timeAcceleration,
            ({ position: pos }) => {
              const distanceGirls = Math.distanceBetween(
                girlsCenter.x,
                girlsCenter.y,
                pos.x,
                pos.y
              );
              if (distanceGirls < girlsJumpDistance.thsh) {
                jumpGirls(timeAcceleration);
              } else {
                resetJumpGirls(timeAcceleration);
              }
              const distance = Math.distanceBetween(
                basketCenter.x,
                basketCenter.y,
                pos.x,
                pos.y
              );
              return distance <= basketBox.width / 2;
            }
          ));
        checkExiting() &&
          (await shootHobbie(basketRef.current, timeAcceleration));
        Observable.push("astronaut-hide-message");

        checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));
        checkExiting() && (await sheryRef.current.resetRifle());

        checkExiting() && (await TimeFunctions.wait(3000 / timeAcceleration));
        sheryRef.current &&
          sheryRef.current.instantMove(
            sheryRef.current.getPos().x,
            (window.innerHeight * 1) / 3
          );
        checkExiting() &&
          (await sheryRef.current.peekRifle(
            -peekRifleXOffset,
            30 * timeAcceleration
          ));
        checkExiting() &&
          (await sheryRef.current.target(
            girlsCenter.x,
            girlsCenter.y + 20,
            90 * timeAcceleration,
            150 / timeAcceleration,
            500 / timeAcceleration,
            true,
            52
          ));

        checkExiting() &&
          (await sheryRef.current.shoot(
            250 * timeAcceleration,
            ({ position: pos }) => {
              const distance = Math.distanceBetween(
                girlsCenter.x,
                girlsCenter.y,
                pos.x,
                pos.y
              );
              return distance <= girlsBox.width / 2;
            }
          ));
        const messagePromise = showMessage(
          "Well, I guess one can live without basketball and girls..",
          8000 / timeAcceleration
        );
        const shootGirlsPromise = shootHobbie(
          girlsRef.current,
          timeAcceleration
        );
        checkExiting() &&
          (await Promise.all([messagePromise, shootGirlsPromise]));
        sheryRef.current && sheryRef.current.hideRifle();
      } catch {
        console.warn("HOBBIES - PAGE EXITING");
      }
    },
    [sheryRef, shootHobbie, jumpGirls, resetJumpGirls]
  );

  const initObjects = React.useCallback(() => {
    if (storkRef && storkRef.current)
      storkRef.current.instantMove(window.innerWidth + 500, 50);
    if (piluRef && piluRef.current) {
      piluRef.current.instantResizePercent(25);
      piluRef.current.instantRotate(-20);
      piluRef.current.instantMove(
        storkRef.current.getPos().x +
          storkRef.current.getSize().width -
          (piluRef.current.getSize().width * 4) / 10,
        storkRef.current.getPos().y +
          storkRef.current.getSize().height / 2 +
          (piluRef.current.getSize().height * 3) / 10
      );
    }
    if (sheryRef && sheryRef.current)
      sheryRef.current.instantMove(-1000, -1000);
  }, [storkRef, piluRef, sheryRef]);

  const bringShery = React.useCallback(
    async (timeAcceleration) => {
      try {
        checkExiting() &&
          (await sheryRef.current.pass(
            -500,
            (window.innerHeight * 1) / 3,
            window.innerWidth + 100,
            (window.innerHeight * 2) / 3
          ));
        checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));

        checkExiting() &&
          (await sheryRef.current.pass(
            (window.innerWidth * 2) / 3,
            window.innerHeight + 100,
            (window.innerWidth * 2) / 3,
            -100 - sheryRef.current.getSize().height
          ));
        checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));
        checkExiting() &&
          (await sheryRef.current.peekTop(
            92 * timeAcceleration,
            2500 / timeAcceleration
          ));
        checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));
        sheryRef && sheryRef.current && sheryRef.current.showRifle();
        checkExiting() &&
          (await sheryRef.current.pass(
            (window.innerWidth * 1) / 3,
            -100,
            window.innerWidth + 100,
            (window.innerHeight * 2) / 3
          ));
        checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));
      } catch {
        console.warn("HOBBIES - PAGE EXITING");
      }
    },
    [sheryRef]
  );

  const loveShery = React.useCallback(
    async (timeAcceleration) => {
      sheryRef.current.instantMove(
        astronaut.position.x + astronaut.height,
        -500
      );
      sheryRef.current.switchToPilu();
      sheryRef.current.rotate(65, 65 * timeAcceleration);
      await sheryRef.current.move(
        astronaut.position.x + sheryRef.current.getSize().height / 2,
        astronaut.position.y,
        200 * timeAcceleration
      );
      await TimeFunctions.wait(1000 / timeAcceleration);

      // create heart
      const heartSeconds = 3 / timeAcceleration;
      const img = document.createElement("img");
      img.src = "/heart.png";
      img.classList.add("hobbies-heart");
      img.style.setProperty("--animation-duration", `${heartSeconds}s`);
      img.style.left = `${astronaut.position.x - (astronaut.width * 2) / 3}px`;
      img.style.top = `${astronaut.position.y - astronaut.height / 3}px`;
      document.body.appendChild(img);
      await TimeFunctions.wait(100);
      img.classList.add("animated");
      await TimeFunctions.wait(heartSeconds * 1000);
      document.body.removeChild(img);
    },
    [sheryRef]
  );

  const piluShootHobbie = React.useCallback(
    async (delayMs, timeAcceleration, hobbieElem) => {
      const box = hobbieElem.getBoundingClientRect();
      const radius = box.width / 2;
      const targetX = box.x + radius;
      const targetY = box.y + radius;
      await TimeFunctions.wait(delayMs / timeAcceleration);
      await piluRef.current.shoot(
        250 * timeAcceleration,
        targetX,
        targetY,
        ({ position: pos }) => {
          const distance = Math.distanceBetween(targetX, targetY, pos.x, pos.y);
          return distance <= radius;
        }
      );
      await shootHobbie(hobbieElem, timeAcceleration);
    },
    [piluRef, shootHobbie]
  );

  const bringPilu = React.useCallback(
    async (timeAcceleration) => {
      if (!storkRef || !storkRef.current) return;
      if (!piluRef || !piluRef.current) return;

      // move shery out of the way
      sheryRef.current.rotate(-10, 50 * timeAcceleration);
      const sheryMovePromise = sheryRef.current.move(
        sheryRef.current.getPos().x + piluRef.current.width + 30,
        astronaut.position.y - 30,
        70 * timeAcceleration
      );

      const storkSize = storkRef.current.getSize();
      const piluSize = piluRef.current.getSize();
      const targetX = astronaut.position.x + astronaut.width * 1.4;
      const promise = new Promise((resolve) => {
        storkRef.current.move(
          -500,
          storkRef.current.getPos().y,
          250 * timeAcceleration,
          (pos) => {
            if (pos.x < targetX) resolve();
            else
              piluRef.current.instantMove(
                pos.x + storkSize.width - (piluSize.width * 4) / 10,
                pos.y + storkSize.height / 2 + (piluSize.height * 3) / 10
              );
          }
        );
      });
      await Promise.all([sheryMovePromise, promise]);
      const resizePromise = piluRef.current.resizePercent(
        400,
        200 * timeAcceleration
      );
      const movePromise = piluRef.current.move(
        astronaut.position.x + astronaut.width,
        astronaut.position.y + astronaut.height / 4,
        50 * timeAcceleration
      );
      const shootGamesPromise = piluShootHobbie(
        0,
        timeAcceleration,
        gamesRef.current
      );
      const shootScifiPromise = piluShootHobbie(
        500,
        timeAcceleration,
        scifiRef.current
      );
      const shootMoviesPromise = piluShootHobbie(
        1500,
        timeAcceleration,
        moviesRef.current
      );
      const shootPartyPromise = piluShootHobbie(
        1500,
        timeAcceleration,
        partyRef.current
      );
      const shootBooksPromise = piluShootHobbie(
        2500,
        timeAcceleration,
        booksRef.current
      );
      await Promise.all([resizePromise, movePromise]);
      await shootGamesPromise;
      await shootScifiPromise;
      await shootMoviesPromise;
      await shootPartyPromise;
      await shootBooksPromise;
      await TimeFunctions.wait(2000 / timeAcceleration);
      piluRef.current.removeMask();
    },
    [storkRef, piluRef, piluShootHobbie]
  );

  const piluShootRandomAnimation = React.useCallback(() => {
    if (!piluRef || !piluRef.current) return;
    const randomShots = Math.randomBetween(5, 10);
    for (let i = 0; i < randomShots; i++) piluRef.current.randomShoot();

    const randomInterval = Math.randomBetween(5000, 10000);
    setTimeout(piluShootRandomAnimation, randomInterval);
  }, [piluRef]);

  const pageAnimation = React.useCallback(async () => {
    initObjects();
    var timeAcceleration = 1;
    try {
      checkExiting() && (await TimeFunctions.wait(100 / timeAcceleration));

      checkExiting() && (await bogdanAstronautAppearence(timeAcceleration));
      checkExiting() && (await TimeFunctions.wait(1500 / timeAcceleration));
      checkExiting() &&
        (await showMessage(
          "So, you now want to hear all about my hobbies and pleasures?",
          5000 / timeAcceleration
        ));
      checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));

      checkExiting() &&
        (await showMessage(
          "So be it, lets bring up some of them from that weird alien world...",
          5000 / timeAcceleration
        ));

      checkExiting() && (await showHobbiesAnimation(timeAcceleration));
      checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));
      console.log("SHOWING HOBBIES");
      checkExiting() &&
        (await showMessage(
          "Lets take them 1 by 1...",
          3000 / timeAcceleration
        ));
      checkExiting() && (await TimeFunctions.wait(2000 / timeAcceleration));
      checkExiting() &&
        booksRef.current &&
        booksRef.current.classList.add("hovered");
      checkExiting() &&
        (await showMessage(
          "Books, I am an avid book reader, sometimes reading a book every day. This was one of my passions since highschool.",
          10000 / timeAcceleration
        ));
      checkExiting() &&
        booksRef.current &&
        booksRef.current.classList.remove("hovered");
      checkExiting() && (await TimeFunctions.wait(1000 / timeAcceleration));
      checkExiting() &&
        (await showMessage(
          "WAIT!!!, something is wrong",
          3000 / timeAcceleration
        ));
      checkExiting() && (await TimeFunctions.wait(1000 / timeAcceleration));
      checkExiting() &&
        (await showMessage("Danger is comming...", 3000 / timeAcceleration));
      checkExiting() && (await bringShery(timeAcceleration));

      checkExiting() && (await targetAndShoot(timeAcceleration));
      checkExiting() && (await loveShery(timeAcceleration));
      checkExiting() && (await bringPilu(timeAcceleration));
      checkExiting() &&
        (await showMessage(
          "I guess that's that, come back when we send him to boarding school!",
          10000 / timeAcceleration
        ));

      checkExiting() && (await TimeFunctions.wait(10000 / timeAcceleration));
      piluShootRandomAnimation();
    } catch (err) {
      console.warn("HOBBIES - PAGE EXITING");
    }
  }, [
    bringPilu,
    bringShery,
    targetAndShoot,
    loveShery,
    initObjects,
    piluShootRandomAnimation,
  ]);

  useEffect(() => {
    Hobbies_Exiting = false;
    pageAnimation();
  }, [pageAnimation]);

  const onPageHiding = React.useCallback(async () => {
    Hobbies_Exiting = true;
    Observable.push("astronaut-grow-leave", { onFinish: () => {} });

    // hide shery
    sheryRef &&
      sheryRef.current &&
      (await sheryRef.current.move(
        window.innerWidth + 200,
        -500,
        1000
      ));
    // hide pilu
    piluRef &&
      piluRef.current &&
      (await piluRef.current.move(
        piluRef.current.getPos().x,
        -piluRef.current.getSize().height,
        1000
      ));

    const hobbieItems = document.getElementsByClassName("hobbies-item");
    for (let i = 0; i < hobbieItems.length; i++) {
      hobbieItems[i].classList.add("exiting");
      hobbieItems[i].classList.remove("appeared");
    }
    //centerPointRef.current && centerPointRef.current.leave();
  }, []);
  React.useEffect(() => {
    Observable.subscribe("hiding-page", onPageHiding);
    return () => {
      Observable.unsubscribe(onPageHiding);
    };
  }, [onPageHiding]);

  return (
    <div className="hobbies-container">
      <CenterPoint
        ref= {centerPointRef}
        src="/planets/planet-watter.png"
        style={{ left: "75%", top: "38%" }}
        finalWidth="500px"
        finalHeight="500px"
      ></CenterPoint>
      <div
        className="hobbies-item books"
        ref={booksRef}
        style={{ backgroundImage: `url(/hobbies-icons/books.png)` }}
      />
      <div
        className="hobbies-item party"
        ref={partyRef}
        style={{ backgroundImage: `url(/hobbies-icons/party.png)` }}
      />
      <div
        className="hobbies-item basket"
        ref={basketRef}
        style={{ backgroundImage: `url(/hobbies-icons/basket.png)` }}
      />
      <div
        className="hobbies-item games"
        ref={gamesRef}
        style={{ backgroundImage: `url(/hobbies-icons/games.png)` }}
      />
      <div
        className="hobbies-item scifi"
        ref={scifiRef}
        style={{ backgroundImage: `url(/hobbies-icons/scifi.png)` }}
      />
      <div
        className="hobbies-item girls"
        ref={girlsRef}
        style={{ backgroundImage: `url(/hobbies-icons/girls.png)` }}
      />
      <div
        className="hobbies-item movies"
        ref={moviesRef}
        style={{ backgroundImage: `url(/hobbies-icons/movies.png)` }}
      />

      <AstronautShery ref={sheryRef} x={-500} y={-500} />
      <AstronautPilu ref={piluRef} x={-500} y={-500} />
      <Animated ref={storkRef} x={500} y={500} width={125} height={100}>
        <StorkSprite />
      </Animated>
    </div>
  );
}
