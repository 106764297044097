import React from 'react'
import './sprite.css'

export default function StorkSprite({top=0, left=0, width="125px", height="100px"})
{
    const style = React.useMemo(()=>{
        return {
            top: `${top}`,
            left: `${left}`,
            width: `${width}`,
            height: `${height}`,
            backgroundImage: 'url(/stork-sprite2.png)'
        }
    }, [top, left, width, height])
    return (
        <div className="sprite" style={style}>

        </div>
    )
}