import { useEffect, useRef } from "react";
import "./rating.css";

export default function Rating({
  max,
  value,
  width = "300px",
  height = "25px",
  animationTime = "0.3s",
  delayAnimationStart = 0,
}) {
  var myTimeout = useRef();
  const ref = useRef();
  useEffect(() => {
    myTimeout.current = setTimeout(() => {
      ref.current.style.setProperty("--data-rating", value);
      ref.current.classList.remove("animated");
      void ref.current.offsetWidth;
      ref.current.classList.add("animated");
    }, parseInt(delayAnimationStart));

    return () => {
      clearTimeout(myTimeout.current);
    };
  }, [delayAnimationStart, value]);
  return (
    <div
      className="animated"
      data-rating
      ref={ref}
      style={{
        width,
        height,
        "--data-rating-count": max,
        "--data-rating": 0,
        "--data-rating-animation-time": animationTime,
      }}
    ></div>
  );
}
