const skills = {
  list: [
    {
      id: 1,
      name: "C#",
      image: "/skill-icons/C-Sharp.webp",
      value: 8,
    },
    {
      id: 2,
      name: "Javascript",
      image: "/skill-icons/javascript.png",
      value: 8,
    },
    {
      id: 3,
      name: "PHP",
      image: "/skill-icons/php.png",
      value: 4,
    },
    {
      id: 4,
      name: "Java",
      image: "/skill-icons/java.png",
      value: 3,
    },
    {
      id: 5,
      name: "Python",
      image: "/skill-icons/python.png",
      value: 3,
    },
    {
      id: 6,
      name: "HTML",
      image: "/skill-icons/html.png",
      value: 5,
    },
    {
      id: 7,
      name: "CSS",
      image: "/skill-icons/css.png",
      value: 6,
    },
    {
      id: 8,
      name: "SQL",
      image: "/skill-icons/sql.png",
      value: 5,
    },
    {
      id: 9,
      name: "Cloud (AWS)",
      image: "/skill-icons/aws.png",
      value: 5,
    },
    {
      id: 10,
      name: "Architectures",
      image: "/skill-icons/architecture.png",
      value: 7,
    },
    {
      id: 11,
      name: "Leader / Mentor / Mng",
      image: "/skill-icons/leadership.png",
      value: 6,
    },
    {
      id: 12,
      name: "Communication",
      image: "/skill-icons/communication.png",
      value: 5,
    },
    {
      id: 13,
      name: "Proffesionalism",
      image: "/skill-icons/attitude.png",
      value: 8,
    },
    {
      id: 14,
      name: "Sense of Humor",
      image: "/skill-icons/humor.png",
      value: 10,
    },
    {
      id: 15,
      isOthers: true,
      name: "Other stuff :) - ",
      image: "/skill-icons/others.png",
      value: "React, Vue, Delphi, ASP, ...",
    },
    {
      id: 16,
      name: "Problem Solving",
      image: "/skill-icons/problem-solving.png",
      value: 8,
    },
    {
      id: 17,
      name: "Algorithms",
      image: "/skill-icons/algorithms.png",
      value: 8,
    },
    {
      id: 18,
      name: "ASP",
      image: "/skill-icons/asp.png",
      value: 8,
    },
    {
      id: 19,
      name: "jQuery",
      image: "/skill-icons/jquery.png",
      value: 8,
    },
    {
      id: 20,
      name: "ASP.NET",
      image: "/skill-icons/aspnet.png",
      value: 8,
    },
    {
      id: 21,
      name: ".NET MVC",
      image: "/skill-icons/netmvc.png",
      value: 8,
    },
    {
      id: 22,
      name: "Apache Cordova",
      image: "/skill-icons/cordova.png",
      value: 8,
    },
    {
      id: 23,
      name: ".NET Core",
      image: "/skill-icons/netcore.png",
      value: 8,
    },
    {
      id: 24,
      name: "Docker",
      image: "/skill-icons/docker.png",
      value: 4,
    },
    {
      id: 25,
      name: "Delphi",
      image: "/skill-icons/delphi.png",
      value: 4,
    },
  ],
  skillsPage: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  experiencePage: [
    {
      name: "Technical University of Cluj-Napoca",
      description:
        "2001 - 2006\nComputer Science:\nFocusing on learning and research, TUCN is one of the best Romanian universities. I have acquired a lot of useful skills and know-how there, from CS fundamentals like DS and algorithms, to software & hardware design.",
      image: "/companies/tucn.png",
      skills: [16, 17, 4, 8, 10],
      size: [4, 2],
      pos: [3, 3],
    },
    {
      name: "IKON Soft",
      description:
        "2004 - 2006\nIkonSoft was, at the time, a very small company, but the trust and mentorship I found there was astonishing. As a junior developer, I was able to work as the sole developer on a complex distributed system, learning web apis, HTML/CSS, Delphi and others. This company contributed the most to shaping me as a software engineer. If for some reason you get to see this, thank you guys!!!",
      image: "/companies/ikonsoft.png",
      skills: [18, 1, 2, 6, 7, 25],
      size: [8, 2],
      pos: [10, 6],
    },
    {
      name: "Technoworks",
      description:
        "2006 - 2015\nTechnoworks was also a small company when I joined. Both me and the company grew together, continuously learning by getting exposed to different technologies and techniques. The people there were like family to me. Thanks guys!!!",
      image: "/companies/technoworks.svg",
      size: [8, 2],
      pos: [3, 11],
      skills: [19, 20, 21, 22],
    },
    {
      name: "Yopeso",
      description:
        "2016 - TODAY\nWhen I joined Yopeso, I was a very resourceful developer, but not very tidy and not very close to the 'industry standards'. Yopeso helped me put some order into my work, become more professional and taught me how to work in teams, and, afterwards, how to lead teams.",
      image: "/companies/yopeso.svg",
      size: [6, 2],
      pos: [8, 14],
      skills: [23, 24, 9, 5, 11, 12, 13],
    },
  ],
};
export default skills;
