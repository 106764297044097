Math.angleBetween = function (x1, y1, x2, y2) {
  var dx = x1 - x2;
  var dy = -(y1 - y2);
  var radians = Math.atan2(dy, dx);
  radians = radians < 0 ? Math.abs(radians) : 2 * Math.PI - radians;
  return radians;
};
Math.squaredDistanceBetween = function (x1, y1, x2, y2) {
  return (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2);
};
Math.distanceBetween = function (x1, y1, x2, y2) {
  return Math.sqrt(Math.squaredDistanceBetween(x1, y1, x2, y2));
};
Math.toDegrees = function (radians) {
  var pi = Math.PI;
  return radians * (180 / pi);
};

Math.toRadians = function (degrees) {
  return (degrees * Math.PI) / 180;
};

class Animator {
  constructor() {
    this.__animationFrameId = null;
    this.__previousTimestamp = undefined;
  }

  __animationLoop(timestamp) {
    if (typeof this.__previousTimestamp === "undefined") {
      this.__previousTimestamp = timestamp;
    }
    if (typeof this.__initialTimestamp === "undefined") {
      this.__initialTimestamp = timestamp;
    }
    const elapsed = timestamp - this.__previousTimestamp;
    const elapsedFromBeginning = timestamp - this.__initialTimestamp;
    this.__previousTimestamp = timestamp;
    if (this.__stopAt) {
      if (this.__stopAt < elapsedFromBeginning) {
        this.__promiseResolve();
        this.__stopAt = undefined;
        return;
      }
    }
    if (typeof this.__testFunction === "function") {
      if (
        this.__testFunction({
          position: this.pos,
          rotation: this.rotation,
          velocity: this.velocity,
          acceleration: this.acceleration,
          elapsedFromBeginning,
          width: this.width,
          height: this.height
        })
      ) {
        this.__promiseResolve();
        this.__testFunction = undefined;
        return;
      }
    }

    this.run(elapsed);

    this.__animationFrameId = window.requestAnimationFrame(
      this.__animationLoop.bind(this)
    );
  }

  start() {
    this.__previousTimestamp = undefined;
    this.__initialTimestamp = undefined;
    this.__animationFrameId = window.requestAnimationFrame(
      this.__animationLoop.bind(this)
    );
  }

  stop() {
    window.cancelAnimationFrame(this.__animationFrameId);
  }

  kill(payload = null) {
    window.cancelAnimationFrame(this.__animationFrameId);
    if (typeof this.__promiseReject === "function") {
      this.__promiseReject(payload);
    }
    this.__stopAt = undefined;
    this.__testFunction = undefined;
    this.__promise = undefined;
    this.__promiseResolve = undefined;
    this.__promiseReject = undefined;
  }

  run(elapsed) {}

  runFor(milliseconds) {
    this.__testFunction = undefined;
    this.__promise = new Promise((resolve, reject) => {
      this.__promiseResolve = resolve;
      this.__promiseReject = reject;
      this.__stopAt = milliseconds;
      this.start();
    });
    return this.__promise;
  }

  runUntil(testFunction) {
    if (typeof testFunction !== "function") {
      throw new Error("runUntil needs a test function returning a boolean");
    }
    this.__stopAt = null;
    this.__testFunction = testFunction;
    this.__promise = new Promise((resolve, reject) => {
      this.__promiseResolve = resolve;
      this.__promiseReject = reject;
      this.start();
    });
    return this.__promise;
  }
}

export class Acceleration {
  setPos(pos) {
    this.pos = pos;
  }
  static createStatic2d(ax, ay) {
    const acceleration = new StaticAcceleration();
    acceleration.type = "static";
    acceleration.set({ x: ax, y: ay, z: 0 });
    return acceleration;
  }

  static createStaticFromMagnitudeAndAngle2d(mag, angle) {
    const acceleration = new StaticAcceleration();
    acceleration.type = "static";
    acceleration.set({
      x: mag * Math.cos(angle),
      y: mag * Math.sin(angle),
      z: 0,
    });
    return acceleration;
  }

  static createTowards2d(x, y, value) {
    const acceleration = new CenterPointAcceleration();
    acceleration.type = "center-point";
    acceleration.set(value);
    acceleration.setCenter(x, y);
    return acceleration;
  }

  static createVariableTowards2d(x, y, value) {
    const acceleration = new VariableCenterPointAcceleration();
    acceleration.type = "center-point-variable";
    acceleration.set(value);
    acceleration.setCenter(x, y);
    return acceleration;
  }

  set(value) {
    this.value = value;
  }
}

class StaticAcceleration extends Acceleration {
  get x() {
    return this.value.x;
  }

  get y() {
    return this.value.y;
  }

  get z() {
    return this.value.z;
  }
}

class CenterPointAcceleration extends Acceleration {
  setCenter(x, y, z = 0) {
    this.center = { x, y, z };
  }
  setPos(pos) {
    this.pos = pos;
    this.alpha = Math.angleBetween(
      this.center.x,
      this.center.y,
      this.pos.x,
      this.pos.y
    );
  }
  get x() {
    return this.value * Math.cos(this.alpha);
  }

  get y() {
    return this.value * Math.sin(this.alpha);
  }

  get z() {
    // we only support 2d for now
    return 0;
  }
}

class VariableCenterPointAcceleration extends Acceleration {
  setCenter(x, y, z = 0) {
    this.center = { x, y, z };
  }
  setPos(pos) {
    this.pos = pos;
    this.alpha = Math.angleBetween(
      this.center.x,
      this.center.y,
      this.pos.x,
      this.pos.y
    );
    this.sqDistance = Math.squaredDistanceBetween(
      this.center.x,
      this.center.y,
      this.pos.x,
      this.pos.y
    );
  }
  get x() {
    return (this.value * Math.cos(this.alpha)) / this.sqDistance;
  }

  get y() {
    return (this.value * Math.sin(this.alpha)) / this.sqDistance;
  }

  get z() {
    // we only support 2d for now
    return 0;
  }
}

export class Velocity {
  constructor() {
    this.x = 0;
    this.y = 0;
    this.z = 0;
  }
  static create2d(x, y) {
    const velocity = new Velocity();
    velocity.x = x;
    velocity.y = y === undefined ? x : y;
    return velocity;
  }
  static create3d(x, y, z) {
    const velocity = new Velocity();
    velocity.x = x;
    velocity.y = y;
    velocity.z = z;
    return velocity;
  }
  static createFromMagnitudeAndAngle2d(mag, angle) {
    const velocity = new Velocity();
    velocity.x = mag * Math.cos(angle);
    velocity.y = mag * Math.sin(angle);
    return velocity;
  }
  static createFromMagnitudeAlongLine2d(mag, x1, y1, x2, y2) {
    const angle = Math.angleBetween(x1, y1, x2, y2);
    return Velocity.createFromMagnitudeAndAngle2d(mag, angle);
  }
}

class AnimatedObject extends Animator {
  constructor(element) {
    super();
    this.element = element;

    this.pos = { x: 0, y: 0, z: 0 };
    this.acceleration = Acceleration.createStatic2d(0, 0);
    this.velocity = Velocity.createFromMagnitudeAndAngle2d(0, 0);
    this.rotation = { x: 0, y: 0, z: 0 };
  }

  update(elapsed) {
    // update position
    if (this.acceleration.length && this.acceleration.length > 0) {
      // acceleration is an array
      // we need to set the current pos to all of them
      this.acceleration.forEach((a) => a.setPos(this.pos));
    } else this.acceleration.setPos(this.pos);

    // update velocity
    if (this.acceleration.length && this.acceleration.length > 0) {
      // we need to apply all accelerations
      this.acceleration.forEach((a) => {
        this.velocity.x += (a.x * elapsed) / 1000;
        this.velocity.y += (a.y * elapsed) / 1000;
        this.velocity.z += (a.z * elapsed) / 1000;
      });
    } else {
      this.velocity.x += (this.acceleration.x * elapsed) / 1000;
      this.velocity.y += (this.acceleration.y * elapsed) / 1000;
      this.velocity.z += (this.acceleration.z * elapsed) / 1000;
    }
  }

  draw() {}

  run(elapsed) {
    this.update(elapsed);
    this.draw(elapsed);
  }
}

export class PositionAnimatedObject extends AnimatedObject {
  update(elapsed) {
    super.update(elapsed);

    // update position
    this.pos.x += (this.velocity.x * elapsed) / 1000;
    this.pos.y += (this.velocity.y * elapsed) / 1000;
    this.pos.z += (this.velocity.z * elapsed) / 1000;
  }
  draw() {
    this.element.style.left = this.pos.x + "px";
    this.element.style.top = this.pos.y + "px";
  }
}

export class SizeAnimatedObject extends AnimatedObject {
  constructor(element, width, height, maintainCenter = false) {
    super(element);
    this.width = width;
    this.height = height;

    this.maintainCenter = maintainCenter;
    if (maintainCenter) {
      this.posAnimatedObject = new PositionAnimatedObject(element);
      this.posAnimatedObject.pos = {
        x: parseInt(element.style.left),
        y: parseInt(element.style.top),
      };
    }
  }

  update(elapsed) {
    super.update(elapsed);
    // update position
    this.width += (this.velocity.x * elapsed) / 1000;
    this.height += (this.velocity.y * elapsed) / 1000;
    if (this.posAnimatedObject) {
      this.posAnimatedObject.velocity.x = -this.velocity.x / 2;
      this.posAnimatedObject.velocity.y = -this.velocity.y / 2;
      this.posAnimatedObject.update(elapsed);
    }
  }

  draw() {
    this.element.style.width = this.width + "px";
    this.element.style.height = this.height + "px";
    if (this.posAnimatedObject) {
      this.posAnimatedObject.draw();
    }
  }
}

export class RotateAnimatedObject extends AnimatedObject {
  constructor(element, maintainCenter = false) {
    super(element);
    this.rotation = { x: 0, y: 0, z: 0 };
    if (maintainCenter) {
      element.style.transformOrigin = "50% 50%";
    }
  }

  update(elapsed) {
    super.update(elapsed);
    // update position
    this.rotation.x += (this.velocity.x * elapsed) / 1000;
    this.rotation.y += (this.velocity.y * elapsed) / 1000;
    this.rotation.z += (this.velocity.z * elapsed) / 1000;

    // normalize over 2pi
    const pi2 = 360;
    if (this.rotation.x > pi2) this.rotation.x -= pi2;
    if (this.rotation.y > pi2) this.rotation.y -= pi2;
    if (this.rotation.z > pi2) this.rotation.z -= pi2;
  }

  draw() {
    this.element.style.transform = `rotateX(${this.rotation.x}deg) rotateY(${this.rotation.y}deg) rotateZ(${this.rotation.z}deg)`;
  }
}
