import React from "react";
import "./astronaut-shery.css";
import { Animated } from "../../../components";
import { TimeFunctions } from "./../../../lib/extentions";
import {
  PositionAnimatedObject,
  RotateAnimatedObject,
  Velocity,
} from "../../../lib/animated-object";

export default React.forwardRef(({ x = 0, y = 0 }, ref) => {
  const width = React.useMemo(() => 110, []);
  const widthForPilu = React.useMemo(() => 150, []);
  const height = React.useMemo(() => 230, []);
  const animatedRef = React.useRef();
  const rifleRef = React.useRef();
  const laserRef = React.useRef();

  const peek = async (
    initX,
    initY,
    peekX,
    peekY,
    rotation,
    speedIn,
    speedOut,
    waitOut = 2500
  ) => {
    animatedRef.current.instantRotate(rotation);
    animatedRef.current.instantMove(initX, initY);

    let audio = new Audio("/sounds/slide2.mp3");
    audio.play().catch(() => {});
    await animatedRef.current.move(peekX, peekY, speedIn);

    await TimeFunctions.wait(waitOut);

    audio = new Audio("/sounds/slide2-down.mp3");
    audio.play().catch(() => {});
    await animatedRef.current.move(initX, initY, speedOut);
  };

  const pass = async (initX, initY, endX, endY) => {
    const audio = new Audio("/sounds/zap-3.mp3");
    audio.volume = 0.5;
    audio.play().catch(() => {});

    let angle =
      Math.toDegrees(Math.angleBetween(endX, endY, initX, initY)) + 90;
    let distance = Math.distanceBetween(initX, initY, endX, endY);

    animatedRef.current.instantMove(initX, initY);
    animatedRef.current.instantRotate(angle);
    await animatedRef.current.move(endX, endY, distance / 2);
  };

  const getRifleCenter = React.useCallback(() => {
    const box = rifleRef.current.getElement().getBoundingClientRect();
    return {
      x: box.x + box.width / 2,
      y: box.y + box.height / 2,
    };
  }, [rifleRef]);

  const getRifleTipPosition = React.useCallback(
    (angle) => {
      const rifleTip = { x: 0, y: 20 };
      const box = rifleRef.current.getElement().getBoundingClientRect();
      const x = box.x + rifleTip.x + rifleTip.x * Math.sin(angle);
      const y = box.y + rifleTip.y * Math.cos(angle);

      return { x, y };
    },
    [rifleRef]
  );

  const target = async (
    targetX,
    targetY,
    targetSpeed = 90,
    targetPauseTime = 150,
    focusTime = 3000,
    showLaser = true,
    laserWidthAdjust = 0
  ) => {
    const riflePos = getRifleCenter();
    var angle = Math.angleBetween(riflePos.x, riflePos.y, targetX, targetY);
    if(angle>Math.PI){
      angle = -(Math.PI * 2 - angle);
    }
    let sheryRot = Math.toRadians(animatedRef.current.getRot());
    const angleDelta = angle - sheryRot
    const targetOffset = Math.toRadians(Math.randomBetween(10, 20));
    const distance = Math.distanceBetween(
      riflePos.x,
      riflePos.y,
      targetX,
      targetY
    );

    return new Promise(async (resolve) => {
      if (showLaser) {
        laserRef.current.style.display = "block";
        laserRef.current.style.width = `${distance - laserWidthAdjust}px`;
      }
      await rifleRef.current.rotate(
        Math.toDegrees(angleDelta + targetOffset),
        targetSpeed
      );
      await TimeFunctions.wait(targetPauseTime);
      await rifleRef.current.rotate(
        Math.toDegrees(angleDelta - targetOffset),
        targetSpeed
      );
      await TimeFunctions.wait(targetPauseTime);
      await rifleRef.current.rotate(
        Math.toDegrees(angleDelta + targetOffset / 2),
        targetSpeed / 2
      );
      await TimeFunctions.wait(targetPauseTime);
      await rifleRef.current.rotate(
        Math.toDegrees(angleDelta - targetOffset / 2),
        targetSpeed / 2
      );
      await TimeFunctions.wait(targetPauseTime);
      await rifleRef.current.rotate(Math.toDegrees(angleDelta), targetSpeed / 3);
      await TimeFunctions.wait(focusTime);
      laserRef.current.style.display = "none";
      resolve();
    });
  };
  const shoot = async (speed = 250, shootFinishTestFc = undefined) => {
    return new Promise(async (resolve) => {
      var rifleRotation = Math.toRadians(rifleRef.current.getRot());
      
      var div = document.createElement("div");
      div.className = "bullet";
      document.body.appendChild(div);
      const audio = new Audio("/sounds/shoot.mp3");
      audio.volume = 0.5;
      audio.play().catch(() => {});
      div.posAnimation = new PositionAnimatedObject(div);
      div.rotAnimation = new RotateAnimatedObject(div);
      
      let sheryRot = Math.toRadians(animatedRef.current.getRot());
      rifleRotation = rifleRotation + sheryRot
      const riflePos = getRifleTipPosition(rifleRotation);
      div.posAnimation.pos.y = riflePos.y;
      div.posAnimation.pos.x = riflePos.x;

      div.posAnimation.velocity = Velocity.create2d(-speed, 0);
      const shootAngle = Math.PI + rifleRotation;
      
      div.rotAnimation.rotation = {
        ...div.rotAnimation.rotation,
        z: Math.toDegrees(shootAngle),
      };
      div.rotAnimation.draw();
      div.posAnimation.velocity = Velocity.create2d(
        speed * Math.cos(shootAngle),
        speed * Math.sin(shootAngle)
      );
      if (shootFinishTestFc) {
        await div.posAnimation.runUntil(shootFinishTestFc);
        document.body.removeChild(div);
        resolve();
      } else {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        div.posAnimation.runUntil(({ position }) => {
          if (
            position.x < -100 ||
            position.y < -100 ||
            position.x > screenWidth + 100 ||
            position.y > screenHeight + 100
          ) {
            document.body.removeChild(div);
            return true;
          }
        });
        resolve();
      }
    });
  };

  React.useImperativeHandle(ref, () => ({
    peekTop: async (speed, waitOut = 2500) => {
      const x = window.innerWidth / 2 - width / 2;
      const y = -height;
      const newY = (height * 40) / 100 - height;
      const peekSpeed = speed || (Math.abs((height * 40) / 100) * 2) / 3;
      const hideSpeed = speed || Math.abs((height * 40) / 100);
      await peek(x, y, x, newY, 180, peekSpeed, hideSpeed, waitOut);
    },
    peekBottom: async (speed, waitOut = 2500) => {
      const x = window.innerWidth / 2 - width / 2;
      const y = window.innerHeight;
      const newY = window.innerHeight - (height * 40) / 100;
      const peekSpeed = speed || (Math.abs((height * 40) / 100) * 2) / 3;
      const hideSpeed = speed || Math.abs((height * 40) / 100);
      await peek(x, y, x, newY, 0, peekSpeed, hideSpeed, waitOut);
    },
    peekRight: async (speed, waitOut = 2500, targetY = undefined) => {
      const x = window.innerWidth + (height - width) / 2;
      const y = targetY || window.innerHeight / 2;
      const newX = x - (height * 40) / 100;
      const peekSpeed = speed || (Math.abs((height * 40) / 100) * 2) / 3;
      const hideSpeed = speed || Math.abs((height * 40) / 100);
      await peek(x, y, newX, y, -90, peekSpeed, hideSpeed, waitOut);
    },
    
    peekRifle: async (x, speed = 30, angle = 0) => {
      animatedRef.current.instantRotate(angle);
      rifleRef.current &&
        rifleRef.current.instantMove((-width * 7) / 10, height / 2);
      await rifleRef.current.move((-width * 7) / 10 + x, height / 2, speed);
    },
    peekRifleTop: async (x, y, speed = 30, angle = 0) => {
      animatedRef.current.instantRotate(angle);
      rifleRef.current &&
        rifleRef.current.instantMove((-width * 7) / 10, height / 2);
      await rifleRef.current.move((-width * 7) / 10 + x, height / 2 + y, speed);
    },
    resetRifle: async (speed = 30) => {
      rifleRef.current &&
        rifleRef.current.move((-width * 7) / 10, height / 2, speed);
    },
    instantMove: animatedRef.current.instantMove,
    move: animatedRef.current.move,
    instantRotate: animatedRef.current.instantRotate,
    rotate: animatedRef.current.rotate,
    getSize: animatedRef.current.getSize,
    getPos: animatedRef.current.getPos,
    resizePercent: animatedRef.current.resizePercent,
    switchToDefault: ()=>{
      animatedRef.current.getElement().style.backgroundImage = 'url(/astronaut/astronaut-shery.png'
      animatedRef.current.instantResize(width, height);
    },
    switchToPilu: ()=>{
      animatedRef.current.getElement().style.backgroundImage = 'url(/astronaut/astronaut-shery-2.png)';
      animatedRef.current.instantResize(widthForPilu,height);
    },
    shoot,
    target,
    pass,
    showRifle:()=>{
      rifleRef.current &&
      rifleRef.current.instantMove((-width * 7) / 10, height / 2);
    },
    hideRifle:()=>{
      rifleRef.current &&
      rifleRef.current.instantMove(-5000,-5000);
    }
  }));

  const onObjectClicked = async () => {
    //await target(200, 400);
    await shoot(250);
    //await drawRifleBox();
  };

  React.useEffect(() => {
    rifleRef.current &&
      rifleRef.current.instantMove(-5000,-5000);
  }, [rifleRef, width, height]);

  return (
    <Animated
      ref={animatedRef}
      x={x}
      y={y}
      width={width}
      height={height}
      backgroundUrl="/astronaut/astronaut-shery.png"
      onObjectClicked={onObjectClicked}
    >
      <Animated
        ref={rifleRef}
        backgroundUrl="/astronaut/rifle.png"
        width={180}
        height={70}
      >
        <div className="laser" ref={laserRef} />
      </Animated>
    </Animated>
  );
});
