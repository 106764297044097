import React, { useEffect, useState, useCallback, useRef } from "react";
import "./home.css";

import { Form, TypeWriter, CenterPoint } from "./../../components";

import Observable from "./../../lib/observable";

import { astronaut } from "./../../components/astronaut/";

import { TimeFunctions } from "./../../lib/extentions";


const hiStateMessages = [
  {
    id: 1,
    cps: 8,
    text: "Hi! I am Bogdan. Welcome to my universe!",
  },
  {
    id: 2,
    cps: 8,
    text: "Hopefully you will find your visit interesting...",
  },
  {
    id: 3,
    cps: 8,
    text: "So, lets find out who you are, and start your visit! Type in your name...",
  },
  {
    id: 5,
    cps: 8,
    text: "Thank you very much! Watch out for those meteors, and prepare for FTL...",
  },
];

var HomePageHiding = false;
export default function Home() {
  const [state, setState] = useState("initial");
  const [hiState, setHiState] = useState(0);
  const [hiFormHiding, setHiFormHiding] = useState(false);
  const nameRef = useRef();
  const formRef = useRef();
  const [animationX, setAnimationX] = React.useState(0);
  const [animationY, setAnimationY] = React.useState(0);

  const [showPlanetInfo, setShowPlanetInfo] = React.useState(false);

  const planetFinalSize = React.useMemo(
    () => (window.innerWidth * 35) / 100,
    []
  );
  const planetArriveDuration = React.useMemo(() => 1000, []);
  const [planetX, planetY] = React.useMemo(() => {
    return [window.innerWidth * 0.8, window.innerHeight * 0.1];
  }, []);

  const onNameChanging = useCallback(
    (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        window.visitor = e.target.value;
        setHiState(6);
      }
    },
    [setHiState]
  );

  const hideForms = useCallback(
    async (newState) => {
      const promise = new Promise((resolve, reject) => {
          setHiFormHiding(true);
          setTimeout(() => {
            setState(newState);
            resolve();
          }, 2000);
      });
      return promise;
    },
    [setState, setHiFormHiding]
  );

  const jumpToFTL = async () => {
    setTimeout(async () => {
      await hideForms();
      setState("asteroids");

      // run with astronaut
      Observable.push("astronaut-grow-leave", {
        onFinish: () => {
          setTimeout(() => {
            Observable.push("leave-page");
          }, 4000);
        },
      });
    }, 3000);
  };

  useEffect(() => {
    HomePageHiding = false;
    setTimeout(() => {
      Observable.push("astronaut-command-move", {
        x: 50,
        y: 300,
        speed: 250,
        onFinish: () => {
          const animationX = astronaut.position.x + astronaut.width * 0.5;
          const animationY = astronaut.position.y + astronaut.height * 0.45;
          setAnimationX(animationX);
          setAnimationY(animationY);
          if(HomePageHiding)return;

          Observable.push("astronaut-command-move-left-arm", {
            onMidPoint: () => {
              if(HomePageHiding)return;
              setShowPlanetInfo(true);
              setState("hi");
              setTimeout(() => {
                if(HomePageHiding)return;
                setHiState(1);
              }, 3000);
            },
            onFinish: () => {
              if(HomePageHiding)return;
              Observable.push("astronaut-command-move-up-and-down");
            },
          });
        },
      });
    }, 100);
  }, []);

  const initialCaretText = "C:\\";

  const onPageHiding = React.useCallback(async () => {
    HomePageHiding = true;
    await hideForms();
    setShowPlanetInfo(false);
    await TimeFunctions.wait(100);
    Observable.push("astronaut-grow-leave", { onFinish: () => {} });
  }, [hideForms, setShowPlanetInfo]);
  React.useEffect(() => {
    Observable.subscribe("hiding-page", onPageHiding);
    return () => {
      Observable.unsubscribe(onPageHiding);
    };
  }, [onPageHiding]);

  const sayHi = () => {
    if (state === "hi") {
      return (
        <Form
          ref={formRef}
          width="400px"
          height="550px"
          left={500}
          top={300}
          animationX={animationX}
          animationY={animationY}
          hide={hiFormHiding}
        >
          {hiState >= 1 && !hiFormHiding && (
            <div className="text-message">
              {" "}
              <TypeWriter
                initialText={initialCaretText}
                text={hiStateMessages[0].text}
                charsPerSecond={hiStateMessages[0].cps}
                onDoneTyping={addHighState}
              />
            </div>
          )}
          {hiState >= 2 && !hiFormHiding && (
            <div>
              '{hiStateMessages[0].text.split(" ")[0]}' is not recognized as an
              internal or external command, operable program or batch file.
            </div>
          )}
          {hiState >= 2 && !hiFormHiding && (
            <div className="text-message">
              <TypeWriter
                initialText={initialCaretText}
                text={hiStateMessages[1].text}
                charsPerSecond={hiStateMessages[1].cps}
                onDoneTyping={addHighState}
              />
            </div>
          )}
          {hiState >= 3 && !hiFormHiding && (
            <div>
              '{hiStateMessages[1].text.split(" ")[0]}' is not recognized as an
              internal or external command, operable program or batch file.
            </div>
          )}
          {hiState >= 3 && !hiFormHiding && (
            <div className="text-message">
              <TypeWriter
                initialText={initialCaretText}
                text={hiStateMessages[2].text}
                charsPerSecond={hiStateMessages[2].cps}
                onDoneTyping={addHighState}
              />
            </div>
          )}
          {hiState >= 4 && !hiFormHiding && (
            <div>
              '{hiStateMessages[2].text.split(" ")[0]}' is not recognized as an
              internal or external command, operable program or batch file.
            </div>
          )}
          {hiState >= 4 && !hiFormHiding && (
            <div className="text-message">
              {initialCaretText}
              <input ref={nameRef} type="text" onKeyPress={onNameChanging} />
            </div>
          )}
          {hiState >= 6 && !hiFormHiding && (
            <div>
              '{window.visitor}' is not recognized as an internal or external
              command, operable program or batch file.
            </div>
          )}
          {hiState >= 6 && !hiFormHiding && (
            <div className="text-message">
              <TypeWriter
                initialText={initialCaretText}
                text={"Hi " + window.visitor + "! " + hiStateMessages[3].text}
                charsPerSecond={hiStateMessages[3].cps}
                onDoneTyping={jumpToFTL}
              />
            </div>
          )}
        </Form>
      );
    }
    return null;
  };

  const addHighState = useCallback(() => {
    setTimeout(() => {
      setHiState((oldState) => {
        const newState = oldState + 1;
        if (newState === 4) {
          setTimeout(() => {
            if (nameRef && nameRef.current) nameRef.current.focus();
          }, 500);
        }
        return newState;
      });
    }, 800);
  }, [setHiState]);

  const bringAsteroids = () => {
    if (state === "asteroids") {
      const animationTime = 15000;
      return (
        <>
          <CenterPoint
            src="/asteroids/LeanPoisedFlamingo.webp"
            arriveDuration={animationTime}
            finalWidth="200px"
            finalHeight="200px"
            style={{ left: "66%", top: "38%" }}
          ></CenterPoint>
          <CenterPoint
            src="/asteroids/LavishOffbeatAmethystinepython.webp"
            arriveDuration={animationTime}
            finalWidth="225px"
            finalHeight="225px"
            style={{ left: "85%", top: "61%" }}
          ></CenterPoint>
          <CenterPoint
            src="/asteroids/OptimisticExhaustedHoverfly.webp"
            arriveDuration={animationTime}
            finalWidth="250px"
            finalHeight="250px"
            style={{ left: "48%", top: "78%" }}
          ></CenterPoint>
        </>
      );
    }
    return null;
  };

  return (
    <div className="home-content">
      <CenterPoint
        src="/planets/planet-earth.png"
        arriveDuration={planetArriveDuration}
        finalWidth={planetFinalSize + "px"}
        finalHeight={planetFinalSize + "px"}
        style={{ left: planetX + "px", top: planetY + "px" }}
      ></CenterPoint>
      {showPlanetInfo && (
        <div
          style={{
            position: "absolute",
            width: "250px",
            left: planetX - planetFinalSize - 250 + "px",
            top: "50px",
          }}
        >
          <h1>Home planet</h1>
          <p>
            This is our planet, home to all of us. Let us think on that, and focus more on the things that bring us together, than to the ones breaking us apart.
          </p>
        </div>
      )}

      {sayHi()}

      {bringAsteroids()}
    </div>
  );
}
