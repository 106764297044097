import { useRef, useEffect, useState, useCallback } from "react";
import Observable from "../../lib/observable";

import {
  PositionAnimatedObject,
  SizeAnimatedObject,
  RotateAnimatedObject,
  Velocity,
} from "../../lib/animated-object";

import "./astronaut.css";

class Astronaut {
  setAstronaut(astronaut) {
    this.astronaut = astronaut;
  }

  get position() {
    return this.astronaut && this.astronaut.posAnimation
      ? this.astronaut.posAnimation.pos
      : { x: 0, y: 0, z: 0 };
  }

  get width() {
    return this.astronaut ? this.astronaut.clientWidth : 0;
  }

  get height() {
    return this.astronaut ? this.astronaut.clientHeight : 0;
  }
}

export const astronaut = new Astronaut();

export const showMessage = async (message, timeout) => {
  return new Promise((resolve) => {
    const options = { timeout, onFinish: resolve };
    Observable.push("astronaut-show-message", { message, options });
  });
};

export default function AstronautComponent({
  subsribeToCommands = true,
  onInit,
}) {
  const ref = useRef();
  const [idle, setIdle] = useState(false);

  const show = true;
  const onAstronautClicked = useCallback(() => {
    Observable.push("astronaut-clicked", { });
  },[]);;

  const nextStep = useCallback(async () => {
    if (!ref || !ref.current || !ref.current.posAnimation) return;
    const screenHeight = document.body.clientHeight;
    const screenWidth = document.body.clientWidth;
    try {
      await ref.current.posAnimation.runUntil(
        ({ position, elapsedFromBeginning }) => {
          if (elapsedFromBeginning < 1000) return false;
          return (
            position.x < 0 ||
            position.y < 0 ||
            position.x > screenWidth - 200 ||
            position.y > screenHeight - 300
          );
        }
      );
      const v = ref.current.posAnimation.velocity;
      const p = ref.current.posAnimation.pos;
      const dev = Math.random() * 25 * (Math.random() > 0.5 ? -1 : 1);
      if (p.x < 0 || p.x > screenWidth - 200) {
        ref.current.posAnimation.velocity = Velocity.create2d(-v.x, v.y + dev);
      } else if (p.y < 0 || p.y > screenHeight - 300) {
        ref.current.posAnimation.velocity = Velocity.create2d(v.x + dev, -v.y);
      } else {
        ref.current.posAnimation.velocity = Velocity.create2d(-v.x, -v.y);
      }
      nextStep();
    } catch (ex) {}
  }, []);

  const doIdleAnimation = useCallback(async () => {
    if (!ref || !ref.current || !ref.current.posAnimation) return;

    ref.current.posAnimation.velocity = Velocity.create2d(
      Math.random() * 50 + 30,
      Math.random() * 50 + 30
    );
    nextStep();
  }, [nextStep, ref]);

  const disposeIdleAnimation = useCallback(() => {
    if (!ref.current) return;
    ref.current.posAnimation && ref.current.posAnimation.kill();
    ref.current.rotAnimation && ref.current.rotAnimation.kill();
    ref.current.sizeAnimation && ref.current.sizeAnimation.kill();
  }, [ref]);

  const astronautMove = useCallback(
    async ({ x, y, speed = 80, endAngle = 0, onFinish }) => {
      if (!ref || !ref.current) {
        return;
      }

      disposeIdleAnimation();

      // calculate rotation speed to match the move time
      const { pos } = ref.current.posAnimation;
      const dist = Math.distanceBetween(pos.x, pos.y, x, y);
      const seconds = dist / speed;

      const { rotation } = ref.current.rotAnimation;
      const rotSpeed = (360 - rotation.z + endAngle) / seconds;

      ref.current.rotAnimation.velocity = Velocity.create3d(0, 0, rotSpeed);
      const rotationPromise = ref.current.rotAnimation.runFor(seconds * 1000);
      ref.current.posAnimation.velocity =
        Velocity.createFromMagnitudeAlongLine2d(
          speed,
          x,
          y,
          astronaut.position.x,
          astronaut.position.y
        );
      var oldDifference = Math.distanceBetween(
        ref.current.posAnimation.pos.x,
        ref.current.posAnimation.pos.y,
        x,
        y
      );
      const posPromise = ref.current.posAnimation.runUntil(({ position }) => {
        const dist = Math.distanceBetween(position.x, position.y, x, y);
        if (dist > oldDifference) return true;
        oldDifference = dist;
      });
      await Promise.all([rotationPromise, posPromise]);

      typeof onFinish === "function" && onFinish();
    },
    [ref, disposeIdleAnimation]
  );

  const astronautMoveUpAndDown = useCallback(async () => {
    const speed = 10;
    const runTime = 3000;
    const rotTime = 1000;
    try {
      ref.current.posAnimation.stop();
      ref.current.rotAnimation.stop();

      const isRandomRotation = Math.random() < 0.4;
      if (isRandomRotation) {
        ref.current.rotAnimation.velocity = Velocity.create3d(0, 0, 360);
        await ref.current.rotAnimation.runFor(rotTime);
      } else {
        // move up down
        ref.current.posAnimation.velocity = Velocity.create2d(0, -speed);
        await ref.current.posAnimation.runFor(runTime);
        ref.current.posAnimation.velocity = Velocity.create2d(0, speed);
        await ref.current.posAnimation.runFor(runTime);
      }

      astronautMoveUpAndDown();
    } catch {}
  }, [ref]);

  const astronautMoveLeftArm = useCallback(
    async ({ onMidPoint, onFinish }) => {
      const timeToPocket = 250;
      const timeToFinish = 500;
      if (!ref || !ref.current) {
        return;
      }
      const leftArm = ref.current.querySelector(".left-hand");
      leftArm.classList.remove("animated");
      setTimeout(() => {
        leftArm.classList.add("animated");
        onMidPoint &&
          typeof onMidPoint === "function" &&
          setTimeout(onMidPoint, timeToPocket);
        onFinish &&
          typeof onFinish === "function" &&
          setTimeout(onFinish, timeToFinish);
      }, 200);
    },
    [ref]
  );

  const astronautHideMessage = useCallback(() => {
    if (ref && ref.current) {
      //delete(ref.current.dataset.message);
      ref.current.classList.remove("cta");
    }
  }, [ref]);

  const astronautShowMessage = useCallback(
    ({ message, options }) => {
      if (!message) return;
      if (ref && ref.current) {
        ref.current.dataset.message = message;
        ref.current.classList.add("cta");
        if (options && options.timeout) {
          setTimeout(() => {
            astronautHideMessage();
            if (options && options.onFinish) {
              options.onFinish();
            }
          }, options.timeout);
        }
      }
    },
    [ref, astronautHideMessage]
  );

  const astronautGrowOffScreen = useCallback(
    async ({ onFinish }) => {
      if (!ref || !ref.current) {
        return;
      }
      astronautHideMessage();
      ref.current.posAnimation.stop();
      ref.current.posAnimation.velocity = Velocity.create2d(-200, 0);
      await ref.current.posAnimation.runUntil(({ position }) => {
        return position.x + ref.current.clientWidth < -300;
      });
      onFinish();
    },
    [ref, astronautHideMessage]
  );

  const astronautTeleport = useCallback(
    async ({ x, y }) => {
      if (!ref || !ref.current) {
        return;
      }
      ref.current.posAnimation.pos.x = x;
      ref.current.posAnimation.pos.y = y;
      ref.current.posAnimation.draw();
    },
    [ref]
  );

  const astronautRandomTeleportOffscreen = useCallback(async () => {
    const random = Math.floor(Math.randomBetween(1, 4));
    switch (random) {
      case 1:
        astronautTeleport({
          x: Math.randomBetween(200, window.innerWidth - 200),
          y: -300,
        });
        break;
      case 2:
        astronautTeleport({
          x: -300,
          y: Math.randomBetween(200, window.innerHeight - 200),
        });
        break;
      case 3:
        astronautTeleport({
          x: Math.randomBetween(200, window.innerWidth - 200),
          y: window.innerHeight + 300,
        });
        break;
      case 4:
        astronautTeleport({
          x: window.innerWidth + 300,
          y: Math.randomBetween(200, window.innerHeight - 200),
        });
        break;
      default:
        break;
    }
  }, [astronautTeleport]);

  const astronautStartIdleAnimation = useCallback(async () => {
    setIdle(true);
    doIdleAnimation();
  }, [setIdle, doIdleAnimation]);

  useEffect(() => {
    setIdle(true);
    if (subsribeToCommands) {
      Observable.subscribe("astronaut-command-move", astronautMove);
      Observable.subscribe(
        "astronaut-command-move-up-and-down",
        astronautMoveUpAndDown
      );
      Observable.subscribe(
        "astronaut-command-move-left-arm",
        astronautMoveLeftArm
      );
      Observable.subscribe("astronaut-grow-leave", astronautGrowOffScreen);
      Observable.subscribe("astronaut-teleport", astronautTeleport);
      Observable.subscribe(
        "astronaut-random-teleport-offscreen",
        astronautRandomTeleportOffscreen
      );
      Observable.subscribe("astronaut-idle", astronautStartIdleAnimation);
      Observable.subscribe("astronaut-show-message", astronautShowMessage);
      Observable.subscribe("astronaut-hide-message", astronautHideMessage);
    }
    return () => {
    };
  }, [
    astronautMove,
    astronautMoveUpAndDown,
    subsribeToCommands,
    astronautGrowOffScreen,
    astronautHideMessage,
    astronautMoveLeftArm,
    astronautRandomTeleportOffscreen,
    astronautShowMessage,
    astronautStartIdleAnimation,
    astronautTeleport,
  ]);

  useEffect(() => {
    ref.current.posAnimation = new PositionAnimatedObject(ref.current);
    ref.current.rotAnimation = new RotateAnimatedObject(ref.current, true);
    ref.current.sizeAnimation = new SizeAnimatedObject(
      ref.current,
      200,
      300,
      true
    );

    astronaut.setAstronaut(ref.current);

    if (typeof onInit === "function") {
      const astronautRef = new Astronaut();
      astronautRef.setAstronaut(ref.current);
      onInit(astronautRef);
    }

    ref.current.posAnimation.pos = {
      x: 0,
      y: -290,
    };
  }, [ref, onInit]);

  useEffect(() => {
    async function initIdle() {
      if (
        idle &&
        ref &&
        ref.current &&
        ref.current.rotAnimation &&
        ref.current.posAnimation
      ) {
        ref.current.rotAnimation.velocity = Velocity.create3d(0, 0, 30);
        ref.current.rotAnimation.start();

        ref.current.posAnimation.velocity = Velocity.create2d(40, 80);
        try {
          await ref.current.posAnimation.runFor(4000);
        } catch (ex) {
          return;
        }
        doIdleAnimation();
      } else {
        disposeIdleAnimation();
      }
    }
    initIdle();
  }, [idle, doIdleAnimation, disposeIdleAnimation]);

  return (
    <div
      ref={ref}
      className={show ? "astronaut" : "astronaut right"}
      onClick={onAstronautClicked}
    >
      <img
        className="left-hand "
        src="/astronaut/astronaut-left-hand.png"
        alt="left hand"
      />
      <img
        className="body"
        src="/astronaut/astronaut-no-left-hand.png"
        alt="astronaut"
      />
    </div>
  );
}
