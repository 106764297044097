import { useState } from "react";

import "./App.css";

import Router from "./shared/router";

import Stars from "./components/stars";
import {
  Presentation,
  Home,
  Skills,
  Experience,
  Hobbies,
  Contact,
  Weaknesses,
} from "./pages";

import Astronaut from "./components/astronaut";
import Menu from "./components/menu";

import Observable from "./lib/observable";
import { useEffect } from "react";

import { SmallScreenDenier } from "./components";

import "./lib/extentions";
import Assets from './lib/assets'

window.isTestMode = true;

function App() {
  const [isSmallScreen, setSmallScreen] = useState(
    window.matchMedia("(max-width: 1024px)").matches
  );

  const onScreenSizeChanged = function (args) {
    setSmallScreen(args.matches);
  };

  useEffect(() => {
    const mediaList = window.matchMedia("(max-width: 1024px)");
    mediaList.addEventListener("change", onScreenSizeChanged);

    Observable.subscribe("navigation-started", () => {
      console.log("Navigation started");
    });
    Observable.subscribe("navigation-80%", () => {
      console.log("Navigation 80%");
    });
    Observable.subscribe("navigation-finished", () => {
      console.log("Navigation finished");
    });
  }, []);

  return (
    <div className="App">
      <div className="container">
        <div style={{ isolation: "isolate" }}>
          <Stars />
        </div>

        {!isSmallScreen && (
          <>
            <Menu />
            <Astronaut />
            <div className="site-container">
              <Router>
                <Home name="home" />
                <Presentation name="presentation" />
                <Skills name="skills" />
                <Experience name="experience" />
                <Hobbies name="hobbies" />
                <Contact name="contact" />
                <Weaknesses name="weaknesses" />
              </Router>
            </div>
          </>
        )}


        {isSmallScreen && <SmallScreenDenier />}
      </div>
    </div>
  );
}

Assets
.addSound("StarWarsTrack",'/star-wars-track.mp3')
.addImage("PlanetEarth","/planets/planet-earth.png")
.addImage("BlackHole","/planets/black-hole.png")
.addImage("PlanetYellow","/planets/planet-yellow.png")
.addImage("PlanetWatter","/planets/planet-watter.png")
.addImage("PlanetGreen","/planets/planet-green.png")
.addImage("PlanetRed","/planets/planet-red.png")
.addImage("PlanetBlue","/planets/planet-blue.png")
.preload();

export default App;
