import React from "react";
import {
  Form,
  TypeWriter,
  CenterPoint,
  Scanner,
} from "./../../components";
import { astronaut } from "./../../components/astronaut/";

import Observable from "./../../lib/observable";

import "./contact.css";
import { TimeFunctions } from "./../../lib/extentions";

const ContactPageStates = {
  INIT: 0,
  QUESTION1_RUN: 11,
  QUESTION1_ASK: 12,
  QUESTION2_RUN: 21,
  QUESTION2_ASK: 22,
  QUESTION3_RUN: 31,
  QUESTION3_ASK: 32,
  FINISH: 4,
  FAIL: 5,
};
const ContactPageConfig = {
  TypeWriterCharsPerSecond: 8,
  Question1: {
    Command: "question1.exe",
    Question: "What is the algorithm we found?",
    Answers: [
      {
        Answer: "1) This is an implementation of the Greedy algorithm!",
        IsCorrect: false,
      },
      {
        Answer: "2) This is the sudo-yummy algorithm!",
        IsCorrect: false,
      },
      {
        Answer: "3) This is an backtracking implementation!",
        IsCorrect: true,
      },
      {Answer: "Answer with 1, 2 or 3."}
    ],
  },
  Question2: {
    Command: "question2.exe",
    Question: "With CSS, how are the 'position:absolute' elements positioned?",
    Answers: [
      {
        Answer: "1) Elements are positioned relative to the viewport!",
        IsCorrect: false,
      },
      {
        Answer:
          "2) Elements are absolute, so they are positioned relative to the screen!",
        IsCorrect: false,
      },
      {
        Answer:
          "3) Elements are positioned relative to the first non-static parent!",
        IsCorrect: true,
      },
    ],
  },
  Question3: {
    Command: "question3.exe",
    Question: "What type of algorithm was found by our scanner?",
    Answers: [
      {
        Answer: "1) This is a genetic type algorithm!",
        IsCorrect: true,
      },
      {
        Answer: "2) This is a well known data clustering algorithm!",
        IsCorrect: false,
      },
      {
        Answer: "3) This is a statistical algorithm!",
        IsCorrect: false,
      },
    ],
  },
  FailMessage:
    "WRONG! If you want to contact me, find someone that has a clue about software development to help you out! ",
  SuccessMessage:
    "Congratulations, you can and may contact me at 'bogdan.toda@yahoo.com'. I will get back to you sometime... soon...",
  DriveText: "C:\\",
};
export default function ContactPage() {
  const planetFinalSize = React.useMemo(
    () => (window.innerWidth * 35) / 100,
    []
  );
  const planetArriveDuration = React.useMemo(() => 1000, []);
  const [planetX, planetY] = React.useMemo(() => {
    return [window.innerWidth * 0.8, window.innerHeight * 0.1];
  }, []);

  const [isTabletVisible, setTabletVisible] = React.useState(false);
  const [isExiting, setIsExiting] = React.useState(false);

  const formRef = React.useRef();
  const scannerRef = React.useRef();
  const q1AnswerRef = React.useRef();
  const q2AnswerRef = React.useRef();
  const q3AnswerRef = React.useRef();
  const canvasRef = React.useRef();
  const gameRef = React.useRef();
  const [state, setState] = React.useState(ContactPageStates.INIT);
  const [scannerProps, setScannerProps] = React.useState(null);
  const [hideForm, setHideForm] = React.useState(false);
  const [showPlanetInfo, setShowPlanetInfo] = React.useState(false);
  const [isScanning, setIsScanning] = React.useState(false);
  const [animationX, setAnimationX] = React.useState(0);
  const [animationY, setAnimationY] = React.useState(0);

  const planetArrived = React.useCallback(() => {
    setShowPlanetInfo(true);
  }, [setShowPlanetInfo]);

  const astronautAnimation = React.useCallback(async () => {
    return new Promise((resolve) => {
      Observable.push("astronaut-command-move", {
        x: 50,
        y: 300,
        speed: 250,
        onFinish: () => {
          const animationX = astronaut.position.x + astronaut.width * 0.5;
          const animationY = astronaut.position.y + astronaut.height * 0.45;
          setAnimationX(animationX);
          setAnimationY(animationY);
          resolve();
        },
      });
    });
  }, []);

  const showTablet = React.useCallback( async ()=>{
    Observable.push("astronaut-command-move-left-arm", {
      onMidPoint: () => {
        setTabletVisible(true);
      },
      onFinish: () => {
        Observable.push("astronaut-command-move-up-and-down");
      },
    });
  }, []);

  const showMessage = React.useCallback(async (message, timeout) => {
    return new Promise((resolve) => {
      const options = { timeout, onFinish: resolve };
      Observable.push("astronaut-show-message", { message, options });
    });
  }, []);

  const initState = React.useCallback(async () => {
    await TimeFunctions.wait(1000);
    await astronautAnimation();
    
    await TimeFunctions.wait(3000);
    await showMessage("So you want to contact me now...?", 5000);
    await TimeFunctions.wait(1000);
    await showMessage("But, you must prove you are worthy first!", 6000);
    await TimeFunctions.wait(1000);
    await showMessage("Answer the following 3 questions correctly, to prove your skills and knowledge.", 8000)
    await TimeFunctions.wait(2500);

    showTablet();

    await TimeFunctions.wait(4000);
    if (formRef && formRef.current) {
      const box = formRef.current.getBoundingClientRect();
      setScannerProps({
        sourceX: box.x + box.width / 2,
        sourceY: box.y + (box.height * 0.05) / 2,
      });
    }

    setState(ContactPageStates.QUESTION1_RUN);
  }, [astronautAnimation, formRef, setState, showMessage, showTablet]);

  const failState = React.useCallback(async () => {
    await TimeFunctions.wait(3000);
    await showMessage(ContactPageConfig.FailMessage);
  }, [showMessage]);

  const successState = React.useCallback(async () => {
    await TimeFunctions.wait(3000);
    await showMessage(ContactPageConfig.SuccessMessage);
  }, [showMessage]);

  const onQuestion1RunFinished = React.useCallback(async () => {
    await TimeFunctions.wait(2000);
    setState(ContactPageStates.QUESTION1_ASK);
  }, [setState]);

  const onQ1AnswerChanging = React.useCallback(
    (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        var answer = parseInt(e.target.value) - 1;
        if (
          ContactPageConfig.Question1.Answers[answer] &&
          ContactPageConfig.Question1.Answers[answer].IsCorrect
        )
          setState(ContactPageStates.QUESTION2_RUN);
        else setState(ContactPageStates.FAIL);
      }
    },
    [setState]
  );

  const onQuestion2RunFinished = React.useCallback(() => {
    setState(ContactPageStates.QUESTION2_ASK);
  }, [setState]);

  const onQ2AnswerChanging = React.useCallback(
    (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        var answer = parseInt(e.target.value) - 1;
        if (
          ContactPageConfig.Question2.Answers[answer] &&
          ContactPageConfig.Question2.Answers[answer].IsCorrect
        )
          setState(ContactPageStates.QUESTION3_RUN);
        else setState(ContactPageStates.FAIL);
      }
    },
    [setState]
  );

  const onQuestion3RunFinished = React.useCallback(() => {
    setState(ContactPageStates.QUESTION3_ASK);
  }, [setState]);

  const onQ3AnswerChanging = React.useCallback(
    (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        var answer = parseInt(e.target.value) - 1;
        if (
          ContactPageConfig.Question3.Answers[answer] &&
          ContactPageConfig.Question3.Answers[answer].IsCorrect
        )
          setState(ContactPageStates.FINISH);
        else setState(ContactPageStates.FAIL);
      }
    },
    [setState]
  );

  const showQ1Game = React.useCallback(async()=>{
    await TimeFunctions.wait(1500);
    const BacktrackingProblems = (await import("./Algorithms/backtracking")).default;
    if(!canvasRef || !canvasRef.current)return;
    canvasRef.current.style.left = `${planetX-100}px`;
    canvasRef.current.style.top = `${planetY-75}px`;
    canvasRef.current.width = 200;
    canvasRef.current.height = 200;
    gameRef.current && gameRef.current.clear();
    gameRef.current = new BacktrackingProblems(canvasRef.current);
    gameRef.current.setupSudoku();
    gameRef.current.start();
    canvasRef.current.classList.add("active");
    q1AnswerRef && q1AnswerRef.current && q1AnswerRef.current.focus();
  }, [planetX, planetY]);

  const showQ3Game = React.useCallback(async()=>{
    await TimeFunctions.wait(1500);
    const Genetics = (await import("./Algorithms/genetics")).default;
    if(!canvasRef || !canvasRef.current)return;
    canvasRef.current.style.left = `${planetX-200}px`;
    canvasRef.current.style.top = `${planetY-75}px`;
    canvasRef.current.width = 400;
    canvasRef.current.height = 200;
    gameRef.current && gameRef.current.clear();
    gameRef.current = new Genetics(canvasRef.current, 150, 350, {x:300,y:100}, {x:50,y:100}, 0.2, 0.01, 10);
    gameRef.current.train();
    canvasRef.current.classList.add("active");
    q3AnswerRef && q3AnswerRef.current && q3AnswerRef.current.focus();
  }, [planetX, planetY]);

  React.useEffect(() => {
    if (isExiting) return;
    switch (state) {
      case ContactPageStates.INIT:
        setIsScanning(false);
        initState();
        break;
      case ContactPageStates.QUESTION1_RUN:
        setIsScanning(false);
        break;
      case ContactPageStates.QUESTION1_ASK:
        setIsScanning(true);
        showQ1Game();
        break;
      case ContactPageStates.QUESTION2_RUN:
        setIsScanning(false);
        gameRef && gameRef.current && gameRef.current.clear();
        canvasRef && canvasRef.current && canvasRef.current.classList.remove("active");
        break;
      case ContactPageStates.QUESTION2_ASK:
        setTimeout(() => {
          q2AnswerRef && q2AnswerRef.current && q2AnswerRef.current.focus();
        }, 500);
        break;
      case ContactPageStates.QUESTION3_RUN:
        setIsScanning(false);

        break;
      case ContactPageStates.QUESTION3_ASK:
        setIsScanning(true);
        showQ3Game();
        
        break;
      case ContactPageStates.FAIL:
        setIsScanning(false);
        setHideForm(true);
        gameRef && gameRef.current && gameRef.current.clear();
        canvasRef && canvasRef.current && canvasRef.current.classList.remove("active");
        failState();
        break;
      case ContactPageStates.FINISH:
        setIsScanning(false);
        setHideForm(true);
        gameRef && gameRef.current && gameRef.current.clear();
        canvasRef && canvasRef.current && canvasRef.current.classList.remove("active");
        successState();
        break;
      default:
        break;
    }
  }, [state, initState, isExiting, setIsScanning, failState, successState, showQ1Game, showQ3Game]);

  const onPageHiding = React.useCallback(async () => {
    setIsExiting(true);
    setHideForm(true);
    setShowPlanetInfo(false);
    await TimeFunctions.wait(2000);
    Observable.push("astronaut-grow-leave", { onFinish: () => {} });
  }, [setHideForm, setIsExiting]);
  React.useEffect(() => {
    Observable.subscribe("hiding-page", onPageHiding);
    return () => {
      Observable.unsubscribe(onPageHiding);
    };
  }, [onPageHiding]);

  const scanner = () => {
    if (isScanning && scannerProps && !isExiting) {
      return (
        <>
          <canvas ref={canvasRef} className="game-canvas" width={200} height={200} style={{borderRadius:"30%", left:`${planetX-100}px`, top:`${planetY-75}px`}}></canvas>
          <Scanner
            ref={scannerRef}
            sourceX={scannerProps.sourceX}
            sourceY={scannerProps.sourceY}
            targetX={planetX}
            targetY={planetY}
            rayHeight={planetFinalSize * 3/4}
            raySwipeSize={planetFinalSize / 2}
          />
        </>
      );
    }
    return null;
  };

  const tablet = () => {
    if (isTabletVisible) {
      return (
        <>
          <Form
            hide={hideForm}
            ref={formRef}
            width="400px"
            height="550px"
            left={500}
            top={300}
            animationX={animationX}
            animationY={animationY}
          >
            {(state === ContactPageStates.QUESTION1_RUN ||
              state === ContactPageStates.QUESTION1_ASK) && (
              <TypeWriter
                initialText={ContactPageConfig.DriveText}
                text={ContactPageConfig.Question1.Command}
                charsPerSecond={ContactPageConfig.TypeWriterCharsPerSecond}
                onDoneTyping={onQuestion1RunFinished}
              />
            )}
            {state === ContactPageStates.QUESTION1_ASK && (
              <div>
                  <br/>
                <div>{ContactPageConfig.Question1.Question}</div>
                <br/>
                {ContactPageConfig.Question1.Answers.map((a, i) => (
                  <div key={i}>{a.Answer}</div>
                ))}
                <br/>

                <div className="text-message">
                  {ContactPageConfig.DriveText}
                  <input
                    ref={q1AnswerRef}
                    type="text"
                    onKeyPress={onQ1AnswerChanging}
                  />
                </div>
              </div>
            )}

            {(state === ContactPageStates.QUESTION2_RUN ||
              state === ContactPageStates.QUESTION2_ASK) && (
              <TypeWriter
                initialText={ContactPageConfig.DriveText}
                text={ContactPageConfig.Question2.Command}
                charsPerSecond={ContactPageConfig.TypeWriterCharsPerSecond}
                onDoneTyping={onQuestion2RunFinished}
              />
            )}
            {state === ContactPageStates.QUESTION2_ASK && (
              <div>
                  <br/>
                <div>{ContactPageConfig.Question2.Question}</div>
                <br/>
                {ContactPageConfig.Question2.Answers.map((a, i) => (
                  <div key={i}>{a.Answer}</div>
                ))}
                <br/>

                <div className="text-message">
                  {ContactPageConfig.DriveText}
                  <input
                    ref={q2AnswerRef}
                    type="text"
                    onKeyPress={onQ2AnswerChanging}
                  />
                </div>
              </div>
            )}

          {(state === ContactPageStates.QUESTION3_RUN ||
              state === ContactPageStates.QUESTION3_ASK) && (
              <TypeWriter
                initialText={ContactPageConfig.DriveText}
                text={ContactPageConfig.Question3.Command}
                charsPerSecond={ContactPageConfig.TypeWriterCharsPerSecond}
                onDoneTyping={onQuestion3RunFinished}
              />
            )}
            {state === ContactPageStates.QUESTION3_ASK && (
              <div>
                  <br/>
                <div>{ContactPageConfig.Question3.Question}</div>
                <br/>
                {ContactPageConfig.Question3.Answers.map((a, i) => (
                  <div key={i}>{a.Answer}</div>
                ))}
                <br/>

                <div className="text-message">
                  {ContactPageConfig.DriveText}
                  <input
                    ref={q3AnswerRef}
                    type="text"
                    onKeyPress={onQ3AnswerChanging}
                  />
                </div>
              </div>
            )}
          </Form>
        </>
      );
    }
    return null;
  };
  return (
    <div className="contact-container">
      <CenterPoint
        src="/planets/planet-red.png"
        onArrived={planetArrived}
        arriveDuration={planetArriveDuration}
        finalWidth={planetFinalSize + "px"}
        finalHeight={planetFinalSize + "px"}
        style={{ left: planetX + "px", top: planetY + "px" }}
      ></CenterPoint>
      {showPlanetInfo && (
        <div
          style={{
            position: "absolute",
            width: "250px",
            left: planetX - planetFinalSize - 250 + "px",
            top: "50px",
          }}
        >
          <h1>Planet Contact</h1>
          <p>
            Just at the outskirts of the galaxy, this planet acts as a gateway
            to the source of all knowledge.
          </p>
        </div>
      )}
      {tablet()}
      {scanner()}
    </div>
  );
}
