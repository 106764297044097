import "./small-screen-denier.css";

const smallScreenMessages = [
  "I really appreciate you visiting my website.",
  "Great minds however, do not fit on small screens.",
  "I am patiently waiting for your visit, when you find a bigger screen.",
];

export default function SmallScreenDenier() {
  return (
    <div className="small-screen-denier">
      <h1>
        Hi Visitor
        <br />I am Bogdan, welcome!
      </h1>
      <div>{smallScreenMessages[0]}</div>
      <div>{smallScreenMessages[1]}</div>
      <div>{smallScreenMessages[2]}</div>
    </div>
  );
}
