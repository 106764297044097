import React, { useEffect, useCallback, useRef } from "react";
import "./scanner.css";
import "./../../lib/extentions";

export default React.forwardRef(
  (
    { sourceX, sourceY, targetX, targetY, rayHeight = 50, raySwipeSize = 100 },
    ref
  ) => {
    const rayRef = useRef();
    React.useImperativeHandle(ref, () => ({
      append: (child) => {
        if (rayRef && rayRef.current) {
          rayRef.current.appendChild(child);
        }
      },
    }));
    const scanAnimation = useCallback(() => {
      if (!rayRef || !rayRef.current) {
        return;
      }
      const scanningTarget = { x: targetX, y: targetY };
      const scanningSource = { x: sourceX, y: sourceY };

      const ray = rayRef.current;
      ray.style.top = scanningSource.y + "px";
      ray.style.left = scanningSource.x + "px";
      const distance = Math.distanceBetween(
        scanningTarget.x,
        scanningTarget.y,
        scanningSource.x,
        scanningSource.y
      );

      const angle = Math.angleBetween(
        scanningTarget.x,
        scanningTarget.y,
        scanningSource.x,
        scanningSource.y
      );
      const angleDegrees = Math.toDegrees(angle) % 360;

      ray.style.setProperty("--ray-width", distance + "px");
      ray.style.setProperty("--ray-height", `${rayHeight}px`);
      ray.style.setProperty("--ray-rotate-z", `${angleDegrees}deg`);
      ray.style.setProperty("--ray-swipe-width", `${raySwipeSize}px`);
      ray.classList.add("animated");
      ray.style.width = `${distance - raySwipeSize / 2}px`;
      ray.style.top =
        scanningSource.y -
        ((Math.cos(angle) > 0 ? +1 : -1) * rayHeight) / 2 +
        "px";
    }, [rayRef, sourceX, sourceY, targetX, targetY, rayHeight, raySwipeSize]);

    useEffect(() => {
      scanAnimation();
    }, [scanAnimation]);

    return <div className="scanner-ray" ref={rayRef}></div>;
  }
);
