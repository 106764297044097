// helper functions
function getAngleBy2Points(x1, y1, x2, y2) {
  var dx = x1 - x2;
  var dy = -(y1 - y2);
  var radians = Math.atan2(dy, dx);
  radians = radians < 0 ? Math.abs(radians) : 2 * Math.PI - radians;
  return radians;
}

export default class FTLAnimation {
  constructor(canvas) {
    this.animationFrameTimestamp = undefined;
    this.starsSpeed = 300;
    this.animationFrameId = undefined;
    this.stars = [];
    this.starApproachingAge = 1500; // milliseconds
    this.starDissapearingAge = 3000;
    this.center = { x: 0, y: 0 };
    this.accelerationAnimationTime = 1000;
    this.initialStarSpeed = 50;
    this.fullStarSpeed = 500;

    this.setCanvas(canvas);
  }

  setCanvas(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
    this.center = {
      x: canvas.width / 2,
      y: canvas.height / 2,
    };
    this.height = canvas.height;
    this.width = canvas.width;
    this.__setInitialCanvasStyles();
    this.initStars();
  }

  initStars() {
    const minFTLStars = 50;
    const maxFTLStars = 100;
    const count = Math.random() * (maxFTLStars - minFTLStars + 1) + minFTLStars;
    this.stars = this.__generateRandomStars(count);
  }

  __setInitialCanvasStyles() {
    this.ctx.strokeStyle = "white";
    this.ctx.lineWidth = 0.5;
    this.ctx.fillStyle = "white";
    this.canvas.style.background =
      "radial-gradient(closest-side, #3f87a688, #ebf8e188, #f69d3c88)";
    this.canvas.style.backgroundPosition = "center";
    this.canvas.style.backgroundSize = "300% 300%";
    this.canvas.style.transition =
      "background-size " +
      this.accelerationAnimationTime / 1000 +
      "s ease-in-out";
  }

  __setCanvasFTLBackground() {
    this._oldCanvasBackground = this.canvas.style.background;
    this.canvas.style.background =
      "radial-gradient(closest-side, #3f87a688, #ebf8e188, #f69d3c88)";
    this.canvas.style.backgroundSize = "100% 100%";
    this.canvas.style.backgroundPosition = "center";
  }

  __resetCanvasBackground() {
    this.canvas.style.backgroundSize = "300% 300%";
    //this.canvas.style.background = this._oldCanvasBackground;
  }

  __generateRandomStars(count) {
    const centerXExclusion = this.center.x / 2;
    const centerYExclusion = this.center.y / 2;
    const points = [];
    const timestamp = new Date().getTime();
    while (points.length < count) {
      const x = Math.random() * this.width;
      const y = Math.random() * this.height;
      if (
        !Math.insideEllipse(
          x,
          y,
          this.center.x,
          this.center.y,
          centerXExclusion,
          centerYExclusion
        )
      ) {
        points.push({
          x,
          y,
          initialX: x,
          initialY: y,
          initTime: timestamp,
          angle: getAngleBy2Points(x, y, this.center.x, this.center.y),
        });
      }
    }
    return points;
  }

  __FTLAnimationStep(timestamp) {
    if (this.animationFrameTimestamp === undefined) {
      this.animationFrameTimestamp = timestamp;
      // this is the first animation step, so we need to initialize the stars with initial conditions
      this.stars.forEach((star) => {
        star.bornStamp = timestamp;
      });
    }

    this.animationFrameTimestamp = timestamp;

    // clear canvas at each frame
    this.ctx.clearRect(0, 0, this.width, this.height);

    // start lining stars to seem like we are moving
    this.stars.forEach((star) => {
      const { x, y, angle, bornStamp } = star;
      // get distance travelled from birth, and decompose it on Ox, Oy
      const distanceFromBirth =
        (this.starsSpeed * (timestamp - bornStamp)) / 1000;
      const dx = distanceFromBirth * Math.cos(angle);
      const dy = distanceFromBirth * Math.sin(angle);

      // if star is
      if (timestamp - bornStamp > this.starApproachingAge) {
        // after a while we need to move the initial point of the star accross the line as well
        const time = timestamp - bornStamp - this.starApproachingAge;
        const distanceToTranslate = (this.starsSpeed * time) / 1000;
        const tdx = distanceToTranslate * Math.cos(angle);
        const tdy = distanceToTranslate * Math.sin(angle);
        star.x = star.initialX + tdx;
        star.y = star.initialY + tdy;
      }

      this.ctx.beginPath();

      // create gradient line stroke style
      const gradient = this.ctx.createLinearGradient(x, y, x + dx, y + dy);
      gradient.addColorStop(0, "transparent");
      gradient.addColorStop(1, "#fff");
      this.ctx.strokeStyle = gradient;

      // draw line
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x + dx, y + dy);
      this.ctx.stroke();

      // fill a small growing circle showing that the star is getting near
      const sizePercent = distanceFromBirth / this.center.y; // consider max size at line lenght of half a screen height
      const starRadius = sizePercent * 5; // max 5px star radius
      this.ctx.arc(x + dx, y + dy, starRadius, 0, Math.PI * 2);
      this.ctx.fill();
    });

    // remove very old stars
    this.stars = this.stars.filter(
      (a) => timestamp - a.bornStamp < this.starDissapearingAge
    );

    // lets add some new random amount of stars
    const newStars = this.__generateRandomStars(Math.random() * 10);
    newStars.forEach((star) => (star.bornStamp = timestamp));
    this.stars = [...this.stars, ...newStars];

    this.animationFrameId = window.requestAnimationFrame(
      this.__FTLAnimationStep.bind(this)
    );
  }

  jumpToFTL() {
    this.initStars();
    this.animationFrameTimestamp = undefined;

    this.starsSpeed = this.initialStarSpeed;
    setTimeout(() => {
      this.starsSpeed = this.fullStarSpeed;
    }, this.accelerationAnimationTime);
    setTimeout(() => {
      this.animationFrameId = window.requestAnimationFrame(
        this.__FTLAnimationStep.bind(this)
      );
    }, 200);
    this.__setCanvasFTLBackground();
  }

  stopFTL() {
    this.starsSpeed = this.initialStarSpeed;
    this.__resetCanvasBackground();
    setTimeout(() => {
      window.cancelAnimationFrame(this.animationFrameId);
      this.ctx.clearRect(0, 0, this.width, this.height);
    }, this.accelerationAnimationTime / 3);
  }
}
