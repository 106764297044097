import React, { useEffect, useCallback, useRef } from "react";
import "./experience.css";
import Game from "./game";
import Observable from "./../../lib/observable";
import CenterPoint from "../../components/center-point";

import { showMessage } from "./../../components/astronaut/";
import { TimeFunctions } from "../../lib/extentions";

const snakeGame = new Game();
export default React.memo(function () {
  const containerRef = useRef();

  useEffect(() => {}, [containerRef]);

  const onKeyDown = useCallback((e) => {
    snakeGame && snakeGame.onKeyDown(e);
  }, []);

  const onGoodieFound = useCallback((goodie) => {
    console.log("GOODIE", goodie);
    snakeGame.pause();
    Observable.push("astronaut-show-message", {
      message: `${goodie.name}\n${goodie.description}`,
    });
  }, []);

  const onGameStarted = useCallback(() => {
    console.log("GAME STARTED");
    Observable.push("astronaut-hide-message");
  }, []);

  const showGame = React.useCallback(()=>{
    if (containerRef && containerRef.current) {
        snakeGame.setContainer(containerRef.current);
        snakeGame.construct();
        snakeGame.onGoodieFound = onGoodieFound;
        snakeGame.setOnStart(onGameStarted);
        containerRef.current.classList.add("visible");
      }
  }, [containerRef, onGoodieFound, onGameStarted])

  const astronautAnimation = React.useCallback(async () => {
    await TimeFunctions.wait(100);
    await new Promise((resolve)=>{
      Observable.push("astronaut-command-move", {
        x: 50,
        y: 300,
        speed: 250,
        onFinish: () => {
          Observable.push("astronaut-command-move-up-and-down");
          resolve();
        },
      });
    });
  }, []);

  const pageAnimation = React.useCallback(async()=>{
    const timeAcceleration = 1
    await astronautAnimation();
    await TimeFunctions.wait(2000 / timeAcceleration);
    await showMessage("Your scanners indicate a large ancient structure on the surface of that desert planet.", 6000 / timeAcceleration);
    await showMessage("Let us magnify it and see what it is...", 4000 / timeAcceleration);
    await showGame(timeAcceleration);

    await TimeFunctions.wait(2000/timeAcceleration);
    await showMessage("It appears to be a snake-like game...", 5000/timeAcceleration);
    showMessage("Use arrows/WASD keys to change direction, and SPACE to pause.");
  }, [astronautAnimation, showGame])

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    pageAnimation();
    return () => {
      console.log("Removing event listener");
      document.removeEventListener("keydown", onKeyDown);
      snakeGame && snakeGame.pause();
      snakeGame && snakeGame.reset();
    };
  }, [onKeyDown, pageAnimation]);

  const onPageHiding = React.useCallback(async () => {
    if(containerRef && containerRef.current){
      containerRef.current.classList.remove("visible");
    }
    Observable.push("astronaut-grow-leave", { onFinish: () => {} });
  }, [containerRef]);
  React.useEffect(() => {
    Observable.subscribe("hiding-page", onPageHiding);
    return () => {
      Observable.unsubscribe(onPageHiding);
    };
  }, [onPageHiding]);

  return (
    <div className="experience-container">
      <CenterPoint
        src="/planets/planet-yellow.png"
        style={{ right: "15%", top: "38%" }}
        finalWidth="500px"
        finalHeight="500px"
      ></CenterPoint>
      <div className="snake-container" ref={containerRef}></div>
    </div>
  );
});
