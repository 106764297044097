import React, { useEffect } from "react";
const starCount = 500;

const animateStarsBlink = function () {
  const stars = document.getElementsByTagName("s");
  if (stars.length > 0) {
    const starsToAnimate = Math.random() * 15;
    for (let i = 0; i < stars.length; i++) {
      const star = stars[i];
      star.classList.remove("blink");
    }
    for (let i = 0; i < starsToAnimate; i++) {
      const randomStarIndex = Math.floor(Math.random() * stars.length);
      const star = stars[randomStarIndex];
      star.classList.add("blink");
    }
  }
  setTimeout(animateStarsBlink, Math.random() * 8000);
};
const generateShootingStar = function () {
  const elem = document.createElement("s");
  elem.style.opacity = Math.random();
  return elem;
};
const animateShootingStar = function () {
  this.star.style.transform = "translateX(" + this.endLeft + "px)";
  this.star.style.transform += "translateY(" + this.endTop + "px)";
};
const removeShootingStar = function () {
  document.body.removeChild(this.star);
};
const animateStarsShootingStar = function () {
  const rootEl = document.getElementById("root");
  const bodyWidth = rootEl.clientWidth;
  const bodyHeight = rootEl.clientHeight;
  const speedRange = 3000;

  const shootingStars = Math.random() * 3;

  for (let i = 0; i < shootingStars; i++) {
    const star = generateShootingStar();
    star.classList.add("shooting-star");
    star.style.opacity = 1;
    document.body.appendChild(star);
    const ltr = Math.random() > 0.5;
    const startLeft = ltr
      ? Math.random() * speedRange + bodyWidth
      : -(Math.random() * speedRange);
    const endLeft = ltr
      ? -(Math.random() * speedRange)
      : Math.random() * speedRange + bodyWidth;

    const startTop = Math.random() * bodyHeight;
    const endTop = Math.random() * bodyHeight;

    star.style.left = startLeft + "px";
    star.style.top = startTop + "px";

    const obj = {
      star,
      endLeft,
      endTop,
    };

    setTimeout(animateShootingStar.bind(obj), 100);

    setTimeout(removeShootingStar.bind(obj), 15000);
  }

  setTimeout(animateStarsShootingStar, Math.random() * 3000);
};

function Stars() {
  const rootEl = document.getElementById("root");
  const bodyWidth = rootEl.clientWidth;
  const bodyHeight = rootEl.clientHeight;

  const generateStarStyle = function () {
    return {
      left: Math.random() * bodyWidth * 1.5,
      top: Math.random() * bodyHeight,
      opacity: Math.random(),
    };
  };

  const generateStars = function () {
    const starsComponents = [];
    for (let i = 0; i < starCount; i++) {
      starsComponents.push(
        <s style={generateStarStyle()} key={i} className="istar"></s>
      );
    }
    return starsComponents;
  };

  useEffect(() => {
    animateStarsBlink();
    animateStarsShootingStar();
  }, []);

  return <>{generateStars()}</>;
}

export default React.memo(Stars);
