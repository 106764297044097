import React, { useEffect, useRef, useCallback } from "react";

function TypeWriter({ text, initialText, charsPerSecond, onDoneTyping }) {
  const ref = useRef();
  var myTimeout = useRef();
  const write = useCallback(() => {
    const container = ref.current;
    const textContainer = container.querySelector("div");
    const audio = container.querySelector("audio");
    const textToWrite = container.dataset.text;
    const characters = textToWrite.length;
    const animationTime = 1000 / charsPerSecond;

    var charIndex = 0;
    const writeOneCharacter = () => {
      textContainer.innerHTML += textToWrite[charIndex];
      charIndex++;
      if (charIndex < characters) {
        if (audio.paused) {
          audio.currentTime = 0;
          audio.play().catch(() => {});
        }
        myTimeout.current = setTimeout(writeOneCharacter, animationTime);
      } else {
        audio.pause();
        onDoneTyping && typeof onDoneTyping === "function" && onDoneTyping();
      }
    };
    myTimeout.current = setTimeout(writeOneCharacter, animationTime);
  }, [myTimeout, charsPerSecond, onDoneTyping]);

  useEffect(() => {
    if (ref && ref.current) write();
    const current = ref.current;

    return () => {
      clearTimeout(myTimeout);
      if (current) {
        current.querySelector("audio").pause();
      }
    };
  }, [myTimeout, write]);

  return (
    <div ref={ref} className="type-writer" data-text={text}>
      <div>{initialText}</div>
      <audio src="./type-writer/ES_Computer Keyboard 2.mp3"></audio>
    </div>
  );
}

//export default TypeWriter;
export default React.memo(TypeWriter);
