import { useRef, useEffect } from "react";

import FTLAnimation from "./../lib/ftl-animation";
import Observable from "./../lib/observable";

var ftlNavigation = undefined;
var ftl = false;
export default function Menu() {
  const canvasRef = useRef();
  const menuItemRef = useRef();
  const menuContainer = useRef();
  const rootEl = document.getElementById("root");
  const bodyWidth = rootEl.clientWidth;
  const bodyHeight = rootEl.clientHeight;

  const computeMenuPoints = (cnt) => {
    const circleAngle = 360;
    const angleDif = circleAngle / cnt;
    const radius = 50;
    const offset = 50;

    const points = [];
    let angle = 0;
    for (let i = 0; i < cnt; i++) {
      const x = radius * Math.cos(Math.toRadians(angle)) + offset;
      const y = radius * Math.sin(Math.toRadians(angle)) + offset;
      points.push({ x, y });
      angle += angleDif;
    }
    return points;
  };

  const toggleNavigation = function () {
    if (ftl) {
      ftlNavigation.stopFTL();
      setTimeout(() => {
        menuContainer &&
          menuContainer.current &&
          menuContainer.current.classList.remove("active");
      }, 500);
    } else {
      Observable.push("astronaut-random-teleport-offscreen");
      ftlNavigation.jumpToFTL();
      setTimeout(() => {
        menuContainer &&
          menuContainer.current &&
          menuContainer.current.classList.add("active");
      }, 1500);
    }
    ftl = !ftl;
  };

  useEffect(() => {
    ftlNavigation = new FTLAnimation(canvasRef.current);
    Observable.subscribe("leave-page", () => {
      Observable.push("leaving", null);
      setTimeout(() => {
        toggleNavigation();
        setTimeout(() => {
          Observable.push("in-menu", null);
        }, 250);
      }, 2000);
    });
    if (window.location.pathname === "/menu") {
      toggleNavigation();
    }
    return () => {
      ftlNavigation.stopFTL();
      ftlNavigation = null;
    };
  }, []);

  const setCurrentMenuItem = function (item) {
    menuItemRef.current.innerHTML = item;
  };
  const resetCurrentMenuItem = function () {
    menuItemRef.current.innerHTML = "";
  };

  const selectMenuItem = function (item) {
    menuContainer.current.classList.remove("active");
    setTimeout(() => {
      Observable.push("navigating-80%", item);
    }, 2000);
    setTimeout(() => {
      toggleNavigation();
      Observable.push("navigating", item);
    }, 2500);
  };
  const menuItems = [
    {
      image: "/planets/planet-earth.png",
      name: "home",
      label: "Home",
    },
    {
      image: "/planets/black-hole.png",
      name: "presentation",
      label: "About",
    },
    {
      image: "/planets/planet-yellow.png",
      name: "experience",
      label: "Education & Experience",
    },
    {
      image: "/planets/planet-watter.png",
      name: "hobbies",
      label: "Hobbies",
    },
    {
      image: "/planets/planet-green.png",
      name: "skills",
      label: "Skills",
    },
    {
      image: "/planets/planet-red.png",
      name: "contact",
      label: "Contact",
    },
    {
      image: "/planets/planet-blue.png",
      name: "weaknesses",
      label: "Weaknesses",
    },
  ];
  const points = computeMenuPoints(menuItems.length);

  return (
    <>
      <canvas
        style={{ position: "absolute", width: "100%", height: "100%" }}
        ref={canvasRef}
        width={bodyWidth}
        height={bodyHeight}
      ></canvas>
      <div
        className="planets-menu"
        style={{
          width: "calc( 100% + 1000px )",
          height: "calc( 100% + 1000px )",
          top: "-100px",
          left: "-100px",
        }}
        ref={menuContainer}
      >
        {menuItems.map((item, index) => (
          <img
            key={item.name}
            src={item.image}
            alt={item.label}
            style={{ top: `${points[index].x}%`, left: `${points[index].y}%` }}
            onMouseEnter={() => setCurrentMenuItem(item.label)}
            onMouseOut={() => resetCurrentMenuItem()}
            onClick={() => selectMenuItem(item.name)}
          />
        ))}
      </div>
      <div className="planets-menu-selected-item" ref={menuItemRef}></div>
    </>
  );
}
