import Preloader from './preloader'

const Assets = {
    images:{},
    sounds:{},
    addImage: function(name, url){
        this.images[name] = url;
        Preloader.addImage(url);
        return this;
    },

    addSound: function(name, url){
        this.sounds[name] = url;
        Preloader.addAudio(url);
        return this;
    },

    preload: function(){
        Preloader.preload();
    }
}
export default Assets;