import React, { useRef, useEffect } from "react";

import "./center-point.css";

export default React.forwardRef(function CenterPoint( {
  src,
  style,
  finalWidth = "auto",
  finalHeight = "500px",
  arriveDuration = 1000,
  onArrived,
}, forwardRef) {
  const ref = useRef();

  useEffect(() => {
    ref.current.style.setProperty("--final-width", finalWidth);
    ref.current.style.setProperty("--final-height", finalHeight);
    ref.current.style.setProperty("--arrive-duration", arriveDuration + "ms");
    ref.current.classList.add("grow-from-nothing");
    onArrived &&
      setTimeout(() => {
        onArrived();
      }, arriveDuration);
  }, [ref, arriveDuration, finalWidth, finalHeight, onArrived]);

  React.useImperativeHandle(forwardRef, ()=>({
    leave: ()=>{
      console.log("hiding");
      const box = ref.current.getBoundingClientRect();
      ref.current.classList.remove('grow-from-nothing')
      ref.current.style.width = `${box.width}px`;
      ref.current.style.height = `${box.height}px`;
      ref.current.style.setProperty("--final-width", "2000px");
      ref.current.style.setProperty("--final-height", "2000px");
      ref.current.classList.add('grow-off-screen')
    }
  }), []);

  return (
    <img
      src={src}
      ref={ref}
      style={style}
      className="center-point"
      alt="centerpoint object"
    ></img>
  );
});
