Math.randomBetween = function (min, max) {
  return Math.random() * (max - min + 1) + min;
};

Math.angleBetween = function (x1, y1, x2, y2) {
  var dx = x1 - x2;
  var dy = -(y1 - y2);
  var radians = Math.atan2(dy, dx);
  radians = radians < 0 ? Math.abs(radians) : 2 * Math.PI - radians;
  return radians;
};
Math.squaredDistanceBetween = function (x1, y1, x2, y2) {
  return (x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2);
};
Math.distanceBetween = function (x1, y1, x2, y2) {
  return Math.sqrt(Math.squaredDistanceBetween(x1, y1, x2, y2));
};
Math.toDegrees = function (radians) {
  var pi = Math.PI;
  return radians * (180 / pi);
};

Math.toRadians = function (degrees) {
  return (degrees * Math.PI) / 180;
};

Math.angle = function (x1, y1, x2, y2) {
  var dx = x1 - x2;
  // Minus to correct for coord re-mapping
  var dy = -(y1 - y2);

  var inRads = Math.atan2(dy, dx);

  // We need to map to coord system when 0 degree is at 3 O'clock, 270 at 12 O'clock
  if (inRads < 0) inRads = Math.abs(inRads);
  else inRads = 2 * Math.PI - inRads;

  return Math.toDegrees(inRads);
};

Math.insideEllipse = function (x, y, h, k, r1, r2) {
  const dx = (x - h) * (x - h);
  const dy = (y - k) * (y - k);
  return dx / (r1 * r1) + dy / (r2 * r2) <= 1;
};

const TimeFunctions = {
  wait: async function (ms) {
    return new Promise((r, j) => setTimeout(r, ms));
  },
};

export { TimeFunctions };
