import React from "react";
import {
  Form,
  TypeWriter,
  Rating,
  CenterPoint,
  Scanner,
} from "./../../components";
import { astronaut } from "./../../components/astronaut/";

import Observable from "./../../lib/observable";

import "./skills.css";
import skills from "./../../data/skills";
import { TimeFunctions } from "./../../lib/extentions";

const SkillPageStates = {
  INIT: 0,
  INTRO: 1,
  INTRO_MESSAGE: 1.1,
  SCANNING: 2,
  FINISH: 3,
};
const SkillPageConfig = {
  TypeWriterCharsPerSecond: 8,
  IntroMessage: "Scanning skills, please stand by...",
  RatingMax: 10,
  RatingHeight: 15,
  RatingWidth: "100%",
  FinishMessage: "Error! Scan incomplete. Memory overflow...",
  DriveText: "C:\\",
  StartCommandText: "scan_skills.exe",
};
export default function SkillsPage() {
  const planetFinalSize = React.useMemo(
    () => (window.innerWidth * 35) / 100,
    []
  );
  const planetArriveDuration = React.useMemo(() => 1000, []);
  const [planetX, planetY] = React.useMemo(() => {
    return [window.innerWidth * 0.8, window.innerHeight * 0.1];
  }, []);

  const [isTabletVisible, setTabletVisible] = React.useState(false);
  const [isExiting, setIsExiting] = React.useState(false);

  const formRef = React.useRef();
  const scannerRef = React.useRef();
  const [state, setState] = React.useState(SkillPageStates.INIT);
  const [scannerProps, setScannerProps] = React.useState(null);
  const [mySkills, setMySkills] = React.useState([]);
  const [hideForm, setHideForm] = React.useState(false);
  const [showPlanetInfo, setShowPlanetInfo] = React.useState(false);

  const [animationX, setAnimationX] = React.useState(0);
  const [animationY, setAnimationY] = React.useState(0);

  const planetArrived = React.useCallback(() => {
    setShowPlanetInfo(true);
  }, [setShowPlanetInfo]);

  const scanSkills = React.useCallback(async () => {
    if (scannerRef.current) {
      await TimeFunctions.wait(2000);
      for (let i = 0; i < skills.skillsPage.length; i++) {
        const skill = skills.list.find((a) => a.id === skills.skillsPage[i]);
        const skillImg = document.createElement("img");
        var randomLocation = Math.random() * (70 - 30) + 30;
        skillImg.src = skill.image;
        skillImg.style.bottom = `${randomLocation}%`;
        skillImg.classList.add("skill-image");

        if (!scannerRef || !scannerRef.current) return;
        scannerRef.current.append(skillImg);
        await TimeFunctions.wait(100);
        skillImg.classList.add("animated");
        await TimeFunctions.wait(2100);
        setMySkills((mySkills) => [...mySkills, skill]);
        await TimeFunctions.wait(3000);
      }
      setState(SkillPageStates.FINISH);
    }
  }, [scannerRef, setMySkills, setState]);

  const astronautAnimation = React.useCallback(() => {
    setTimeout(() => {
      Observable.push("astronaut-command-move", {
        x: 50,
        y: 300,
        speed: 250,
        onFinish: () => {
          const animationX = astronaut.position.x + astronaut.width * 0.5;
          const animationY = astronaut.position.y + astronaut.height * 0.45;
          setAnimationX(animationX);
          setAnimationY(animationY);
          Observable.push("astronaut-command-move-left-arm", {
            onMidPoint: () => {
              setTabletVisible(true);
              setTimeout(() => {
                if (formRef && formRef.current) {
                  const box = formRef.current.getBoundingClientRect();
                  setScannerProps({
                    sourceX: box.x + box.width / 2,
                    sourceY: box.y + (box.height * 0.05) / 2,
                  });
                }
                setTimeout(() => {
                  setState(SkillPageStates.INTRO);
                }, 1000);
              }, 3500);
            },
            onFinish: () => {
              Observable.push("astronaut-command-move-up-and-down");
            },
          });
        },
      });
    }, 100);
  }, [setTabletVisible, setScannerProps, formRef, setState]);

  const onFinishIntroCommand = React.useCallback(() => {
    // skip intro message for now
    setState(SkillPageStates.SCANNING);
    //setState(SkillPageStates.INTRO_MESSAGE);
  }, [setState]);

  React.useEffect(() => {
    if (isExiting) return;
    switch (state) {
      case SkillPageStates.INIT:
        astronautAnimation();
        break;
      case SkillPageStates.INTRO:
      case SkillPageStates.INTRO_MESSAGE:
        break;
      case SkillPageStates.SCANNING:
        scanSkills();
        break;
      default:
        break;
    }
  }, [state, setState, astronautAnimation, scanSkills, isExiting]);

  const onPageHiding = React.useCallback(async () => {
    setIsExiting(true);
    setHideForm(true);
    setShowPlanetInfo(false);
    await TimeFunctions.wait(2000);
    Observable.push("astronaut-grow-leave", { onFinish: () => {} });
  }, [setHideForm, setIsExiting]);
  React.useEffect(() => {
    Observable.subscribe("hiding-page", onPageHiding);
    return () => {
      Observable.unsubscribe(onPageHiding);
    };
  }, [onPageHiding]);

  const scanner = () => {
    if (state === SkillPageStates.SCANNING && scannerProps && !isExiting) {
      return (
        <>
          <Scanner
            ref={scannerRef}
            sourceX={scannerProps.sourceX}
            sourceY={scannerProps.sourceY}
            targetX={planetX}
            targetY={planetY}
            rayHeight={planetFinalSize / 2}
            raySwipeSize={planetFinalSize / 4}
          />
        </>
      );
    }
    return null;
  };

  const tablet = () => {
    if (isTabletVisible) {
      return (
        <>
          <Form
            hide={hideForm}
            ref={formRef}
            width="400px"
            height="550px"
            left={500}
            top={300}
            animationX={animationX}
            animationY={animationY}
          >
            {!isExiting && state >= SkillPageStates.INTRO && (
              <TypeWriter
                initialText={SkillPageConfig.DriveText}
                text={SkillPageConfig.StartCommandText}
                charsPerSecond={SkillPageConfig.TypeWriterCharsPerSecond}
                onDoneTyping={onFinishIntroCommand}
              />
            )}

            {!isExiting &&
              mySkills &&
              mySkills.length > 0 &&
              mySkills.map((skill) => (
                <div className="skill-item" key={skill.id}>
                  {!skill.isOthers && (
                    <>
                      <div>{skill.name}</div>
                      <div>
                        <Rating
                          max={SkillPageConfig.RatingMax}
                          value={skill.value}
                          height={SkillPageConfig.RatingHeight}
                          width={SkillPageConfig.RatingWidth}
                        />
                      </div>
                    </>
                  )}
                  {skill.isOthers && (
                    <div style={{ width: "100%" }}>
                      {skill.name} : {skill.value}
                    </div>
                  )}
                </div>
              ))}
            {!isExiting && state >= SkillPageStates.FINISH && (
              <div style={{ color: "red" }}>
                <TypeWriter
                  text={SkillPageConfig.FinishMessage}
                  charsPerSecond={SkillPageConfig.TypeWriterCharsPerSecond}
                />
                }
              </div>
            )}
          </Form>
        </>
      );
    }
    return null;
  };
  return (
    <div>
      <CenterPoint
        src="/planets/planet-green.png"
        onArrived={planetArrived}
        arriveDuration={planetArriveDuration}
        finalWidth={planetFinalSize + "px"}
        finalHeight={planetFinalSize + "px"}
        style={{ left: planetX + "px", top: planetY + "px" }}
      ></CenterPoint>
      {showPlanetInfo && (
        <div
          style={{
            position: "absolute",
            width: "250px",
            left: planetX - planetFinalSize - 250 + "px",
            top: "50px",
          }}
        >
          <h1>Planet SKILLS</h1>
          <p>
            Situated in the left emisphere, and based on eons of evolution, the
            SKILLS planet grew to an enormous size, almost as big as the ego of
            its owner.
          </p>
        </div>
      )}
      {tablet()}
      {scanner()}
    </div>
  );
}
