import React from "react";
import Observable from "./../../lib/observable";
import { TimeFunctions } from "./../../lib/extentions";
import { Form, TypeWriter } from "./../../components";
import { astronaut } from "./../../components/astronaut/";

const WeaknessesStates = {
  INIT: 0,
  TABLET: 1,
  SHOW_GAME_COMMAND: 1.1,
  SHOW_GAME: 2,
  GAME_RULES: 3,
  PLAY: 4,
  EXITING: 10,
};

const WeaknessesPageConfig = {
  StartCommandText: "node dodge-it.js",
  DriveText: "C:\\ ",
  TypeWriterCharsPerSecond: 8,
  Form: {
    width: "400px",
    height: "550px",
    left: 500,
    top: 200,
  },
  Game: {
    width: "340px",
    height: "340px",
    style: { marginTop: "30px", border: "1px solid black" },
  },
  isExiting: false,
};
export default function Weaknesses() {
  const timeAcceleration = 1;
  const [state, setState] = React.useState(WeaknessesStates.INIT);
  const formRef = React.useRef();
  const canvasRef = React.useRef();
  const gameRef = React.useRef();
  const [hideForm, setHideForm] = React.useState(false);

  const [animationX, setAnimationX] = React.useState(0);
  const [animationY, setAnimationY] = React.useState(0);

  const bogdanAstronautAppearence = React.useCallback(
    async (timeAcceleration = 1) => {
      return new Promise((resolve) => {
        const x = 50;
        const y = 330;
        Observable.push("astronaut-command-move", {
          x,
          y,
          speed: 250 * timeAcceleration,
          endAngle: -20,
          onFinish: () => {
            const animationX = astronaut.position.x + astronaut.width * 0.5;
            const animationY = astronaut.position.y + astronaut.height * 0.45;
            setAnimationX(animationX);
            setAnimationY(animationY);
            resolve();
          },
        });
      });
    },
    []
  );
  const showMessage = React.useCallback(async (message, timeout) => {
    if (WeaknessesPageConfig.isExiting) return;
    return new Promise((resolve) => {
      const options = { timeout, onFinish: resolve };
      Observable.push("astronaut-show-message", { message, options });
    });
  }, []);

  const showTablet = React.useCallback(
    async (timeAcceleration) => {
      if (WeaknessesPageConfig.isExiting) return;
      return new Promise((resolve) => {
        if (WeaknessesPageConfig.isExiting) return;
        Observable.push("astronaut-command-move-left-arm", {
          onMidPoint: () => {
            setState(WeaknessesStates.TABLET);
          },
          onFinish: () => {
            Observable.push("astronaut-command-move-up-and-down");
            resolve();
          },
        });
      });
    },
    [setState]
  );

  const onFinishTypingStartGameCommand = React.useCallback(async () => {
    if (WeaknessesPageConfig.isExiting) return;
    const DodgeItGame = (await import("./DodgeIt")).default;
    setState(WeaknessesStates.SHOW_GAME);
    await TimeFunctions.wait(1000 / timeAcceleration);
    gameRef.current = new DodgeItGame(
      canvasRef.current,
      "/dodge-it/hero.png",
      "/dodge-it/enemy.png"
    );
    gameRef.current.hero.setName(window.visitor || "Player");
    await TimeFunctions.wait(1000 / timeAcceleration);
    gameRef.current.setLevel(1);
    gameRef.current.update(0);
    gameRef.current.draw();
  }, [setState, gameRef]);

  const gameFinished = React.useCallback(async () => {
    const score = gameRef.current.hero.score;
    gameRef.current.clean();

    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "It looks that you have lost.",
        5000 / timeAcceleration
      ));
    if (score === 0)
      !WeaknessesPageConfig.isExiting &&
        (await showMessage(
          "You chose to forfeit and suicide.",
          5000 / timeAcceleration
        ));
    else if (score <= 2000)
      !WeaknessesPageConfig.isExiting &&
        (await showMessage("You were terrible.", 5000 / timeAcceleration));
    else if (score <= 4000)
      !WeaknessesPageConfig.isExiting &&
        (await showMessage(
          "You've put up a good fight.",
          5000 / timeAcceleration
        ));
    else
      !WeaknessesPageConfig.isExiting &&
        (await showMessage("You were a champ!!", 5000 / timeAcceleration));

    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "You can play again, by clicking the game, or you can leave by clicking the red button."
      ));
  }, [showMessage]);

  const restartGame = React.useCallback(async () => {
    if (WeaknessesPageConfig.isExiting) return;
    setState(WeaknessesStates.PLAY);
    if (gameRef && gameRef.current) gameRef.current.clean();
    const DodgeItGame = (await import("./DodgeIt")).default;
    gameRef.current = new DodgeItGame(
      canvasRef.current,
      "/dodge-it/hero.png",
      "/dodge-it/enemy.png"
    );
    gameRef.current.start();
    gameRef.current.onFinished = gameFinished;
    Observable.push("astronaut-hide-message");
  }, [setState, gameFinished]);

  const initAnimation = React.useCallback(async () => {
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(2000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await bogdanAstronautAppearence(timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(2000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "Hey! What did you expect ?",
        5000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "There is obviously nothing here. No weaknesses in sight :).",
        5000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage("Except maybe...", 3000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage("I work too much :)", 3000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "or, I am too professional ... ",
        3000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "How about playing a game instead ?",
        5000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting && (await showTablet(timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(4000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      setState(WeaknessesStates.SHOW_GAME_COMMAND);
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(4000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "How do I play, you ask? \nWell, you have your hero astronaut, and your ugly space monster...",
        8000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "Both the astronaut and the monster, fire laser balls :) automatically.",
        8000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "The monster will sometimes fire green shots, and powerups will randomly appear. These are good for you, so catch them !",
        10000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage(
        "Use WASD or arrow keys to move around and dodge the monster's shots.",
        8000 / timeAcceleration
      ));
    !WeaknessesPageConfig.isExiting &&
      (await TimeFunctions.wait(1000 / timeAcceleration));
    !WeaknessesPageConfig.isExiting &&
      (await showMessage("Click the game to start!"));
  }, [bogdanAstronautAppearence, showMessage, showTablet, setState]);

  React.useEffect(() => {
    WeaknessesPageConfig.isExiting = false;
    initAnimation();
  }, [initAnimation]);

  const onPageHiding = React.useCallback(async () => {
    gameRef && gameRef.current && gameRef.current.clean();
    setState(WeaknessesStates.EXITING);
    setHideForm(true);
    WeaknessesPageConfig.isExiting = true;
    await TimeFunctions.wait(2000);
    Observable.push("astronaut-grow-leave", { onFinish: () => {} });
  }, [setHideForm, setState]);
  React.useEffect(() => {
    Observable.subscribe("hiding-page", onPageHiding);
    return () => {
      Observable.unsubscribe(onPageHiding);
    };
  }, [onPageHiding]);

  const showForm = () => {
    if (state >= WeaknessesStates.TABLET) {
      return (
        <Form
          hide={hideForm}
          ref={formRef}
          width={WeaknessesPageConfig.Form.width}
          height={WeaknessesPageConfig.Form.height}
          left={WeaknessesPageConfig.Form.left}
          top={WeaknessesPageConfig.Form.top}
          animationX={animationX}
          animationY={animationY}
        >
          {state !== WeaknessesStates.EXITING &&
            state >= WeaknessesStates.SHOW_GAME_COMMAND && (
              <TypeWriter
                initialText={WeaknessesPageConfig.DriveText}
                text={WeaknessesPageConfig.StartCommandText}
                charsPerSecond={WeaknessesPageConfig.TypeWriterCharsPerSecond}
                onDoneTyping={onFinishTypingStartGameCommand}
              />
            )}
          {state !== WeaknessesStates.EXITING &&
            state >= WeaknessesStates.SHOW_GAME && (
              <canvas
                ref={canvasRef}
                width={WeaknessesPageConfig.Game.width}
                height={WeaknessesPageConfig.Game.height}
                style={WeaknessesPageConfig.Game.style}
                onClick={restartGame}
              ></canvas>
            )}
        </Form>
      );
    } else {
      return null;
    }
  };

  return <div className="weaknesses-container">{showForm()}</div>;
}
