import { useEffect, useRef } from "react";
import Observable from "../../lib/observable";

import CenterPoint from "../../components/center-point";
import { showMessage } from "./../../components/astronaut/";
import "./presentation.css";

var presentationScrollerOffset = 3;
const animatePresentation = function (scroller, topValue, onFinish, onAlmostFinished) {
  var onAlmostFinishedCalled = false;
  const interval = setInterval(() => {
    scroller.style.top = topValue + "px";
    if(onAlmostFinished && !onAlmostFinishedCalled && parseInt(scroller.style.top) + scroller.clientHeight < 300){
      onAlmostFinishedCalled=true;
      onAlmostFinished()
    }
    if (parseInt(scroller.style.top) + scroller.clientHeight < 0) {
      console.log("stopping");
      clearInterval(interval);
      onFinish && typeof onFinish === "function" && onFinish();
    }
    topValue -= presentationScrollerOffset;
  }, 50);
};

export default function Presentation() {
  const ref = useRef();
  const scrollerRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    Observable.subscribe("leaving", () => {
      console.log("leaving", ref.current);
      presentationScrollerOffset = 300;
      ref && ref.current && ref.current.classList.remove("planet-arrive");
      ref && ref.current && ref.current.classList.add("planet-leave");
    });
    setTimeout(() => {
      presentationScrollerOffset = 3;
      animatePresentation(scrollerRef.current, 3000,null, ()=>{
        // almost finished
        showMessage("Thank you for reading, use emergency FTL (bottom right) to leave.");
      });
      if(audioRef && audioRef.current){
        audioRef.current.volume = 0.1;
        audioRef.current.play().catch(() => {});
      }


      Observable.push("astronaut-command-move", {
        x: 50,
        y: 250,
        onFinish: () => {
          Observable.push("astronaut-command-move-up-and-down");
        },
      });
    }, 2000);
    return () => {};
  }, [ref]);
  return (
    <>
      <CenterPoint
        src="/planets/black-hole.png"
        style={{ left: "50%", top: "38%" }}
        finalWidth="500px"
        finalHeight="548px"
      ></CenterPoint>
      <audio src="/star-wars-track.mp3" ref={audioRef}/>
      {/* <img src="/planets/black-hole.png" ref={ref} className="planet-bg planet-arrive" alt="planet-bg"/> */}
      {/* <img src="https://space-facts.com/wp-content/uploads/mercury-transparent.png" ref={ref} className="planet-bg planet-arrive" alt="planet-bg"/> */}
      <div className="presentation-container">
        <div className="presentation-form">
          <div className="presentation-content">
            <div className="presentation-scroller" ref={scrollerRef}>
              <p>
                A long time ago, in a place far-far away, a playful happy child
                was born.
              </p>
              <p>
                Little did he know he will grow to become trapped, permanently and
                forever glued to a computer screen and keyboard. He was to
                become ...
                <br /> a software engineer.
              </p>
              <p>
                Ignorance is bliss, so he was happy, having the time of his life
                playing with other kids.
              </p>
              <p>
                And then, he went to school, and found stem subjects very
                attractive. Understanding how the world works was very interesting to him.
              </p>
              <p>
                For some bizare reason he chose software engineering as his career.</p>
              <p>
                Everything IT seemed cool and interesting, like this CSS text
                magic here. He learned lots of techniques and programming
                languages. He learned to play solo, or be a part of a team.
                He got more and more into it, until...
              </p>
              <p>
                He was trapped... Escape he will? or forever a
                victim of his passion will he be ?
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
